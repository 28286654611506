/*!

=========================================================
* Vue Argon Design System - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system/blob/master/LICENSE.md)

* Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Argon from "./plugins/argon-kit";
import './registerServiceWorker';
import VueGoogleMaps from '@fawmi/vue-google-maps';
import { initializeApp } from 'firebase/app';
import "firebase/messaging";
import firebaseMessaging from './firebase';

const firebaseConfig = {
  apiKey: "AIzaSyCoMNnTaR3fwQJHDUq7uWA4Ck8l-t5dEEc",
  authDomain: "kiara-event.firebaseapp.com",
  projectId: "kiara-event",
  storageBucket: "kiara-event.appspot.com",
  messagingSenderId: "266714720837",
  appId: "1:266714720837:web:432e9cf0452dbe6849512c",
  measurementId: "G-PS9KJVM9EQ"
};

const app = initializeApp(firebaseConfig);

navigator.serviceWorker
  .register("firebase-messaging-sw.js")
  .then((registration) => {
    messaging.useServiceWorker(registration);
  })
  .catch((err) => {});

Vue.config.productionTip = false;
Vue.config.globalProperties.$messaging = firebaseMessaging

Vue.use(Argon);
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBSxWzAWbi5eOVl4vTsIcHV2HkunwhAE7Y',
    libraries: "places"
  },
});
new Vue({
  router,
  render: h => h(App)
}).$mount("#app");