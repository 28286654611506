<template>
  <div>
    <section class="section section-shaped section-lg my-0">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="mb-5">
              <h3>Publish Acara Anda</h3>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="row">
                <div class="col-md-5">
                  <img
                    :src="eventImage"
                    class="img-center img-fluid"
                    style=""
                  />
                </div>
                <div class="col-md-7 p-4">
                  <p class="heading font-weight-bold mb-2">{{ eventTitle }}</p>
                  <small>
                    {{ eventStartDate }} <br />
                    {{ location }}
                  </small>
                  <div class="mt-3">
                    <p>
                      <small>{{ summary }}</small>
                    </p>
                  </div>
                  <div class="row">
                    <div class="col-md-6 mb-2">
                      <router-link
                        class="text-light"
                        :to="{ name: 'createTicket', params: {} }"
                      >
                        <base-button type="light" class="w-100"
                          >Batal</base-button
                        >
                      </router-link>
                    </div>
                    <div class="col-md-6">
                      <base-button
                        type="primary"
                        class="w-100"
                        @click="publishEvent"
                        >Publish Sekarang</base-button
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import axios from "axios";
import debounce from "lodash.debounce";
import { sweatalert } from "@/reactivities/sweatalert.js";

export default {
  components: {},
  created() {
    this.getEvent();
  },
  data() {
    return {
      eventId: this.$route.params.e,
      eventTitle: "",
      eventStartDate: "",
      eventImage: "",
      location: "",
      summary: "",
      urlEditEvent: `${process.env.VUE_APP_API}/api/v1/dashboard/e/basicinfo`,
      urlPublish: `${process.env.VUE_APP_API}/api/v1/dashboard/e/publish`,
      authToken: "Bearer " + JSON.parse(localStorage.getItem("token")),
    };
  },
  methods: {
    getEvent: function () {
      axios
        .get(`${this.urlEditEvent}?id=${this.eventId}`, {
          headers: { Authorization: this.authToken },
        })
        .then(
          (res) => {
            if (res.data.data) {
              this.eventTitle = res.data.data.title;
              this.eventImage = res.data.data.image_url;
              this.eventStartDate = res.data.data.formatted_from;
              this.location = res.data.data.location;
              this.summary = res.data.data.summary;

              let newListTags = res.data.data.tags.map(function (obj) {
                return obj.name;
              });

              this.eventTags = newListTags;
            }
          },
          (err) => {
            if (err.response.data.message) {
              sweatalert.showAlert("error", err.response.data.message);
            } else {
              sweatalert.showAlert("error", "Maaf telah terjadi kesalahan");
            }
          }
        );
    },
    publishEvent: function () {
      axios
        .get(`${this.urlPublish}/${this.eventId}`, {
          headers: { Authorization: this.authToken },
        })
        .then(
          (res) => {
            if (res.data.success) {
              sweatalert.showAlert("success", res.data.message);

              this.reloadPage();
            } else {
              sweatalert.showAlert("warning", res.data.message);

              this.showSettingPayment();
            }
          },
          (err) => {
            if (err.response.data.message) {
              sweatalert.showAlert("error", err.response.data.message);
            } else {
              sweatalert.showAlert("error", "Maaf telah terjadi kesalahan");
            }
          }
        );
    },
    showSettingPayment: function () {
      this.$router.push({ name: "organizerSetting", params: {} });
    },
    reloadPage: debounce((e) => {
      location.reload();
    }, 1000),
  },
};
</script>
<style>
</style>
