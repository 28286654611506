<template>
  <header class="header-global mb-4">
    <div class="container">
      <base-nav
        class="navbar-main"
        :classContainer="'max-width-full'"
        type=""
        effect="light"
        expand
        title="My Kiara Event"
      >
        <template v-slot:contentHeader>
          <div class="col-6 collapse-brand">
            <router-link to="/"> My Kiara Event</router-link>
          </div>
        </template>

        <div class="row float-end">
          <div class="col-5">
            <router-link
              :v-slot="brand"
              class="navbar-brand text-warning"
              :to="{ name: 'detailEvent', params: { event: urlEvent } }"
              v-if="
                storeProgressCreateEvent.isEdit &&
                storeProgressCreateEvent.eventStatus == 'publish'
              "
            >
              Lihat Acara
            </router-link>
          </div>
          <div class="col-5">
            <span
              :v-slot="brand"
              class="navbar-brand text-danger text-left cursor-pointer"
              @click="unPublishEvent"
              v-if="
                storeProgressCreateEvent.isEdit &&
                storeProgressCreateEvent.eventStatus == 'publish'
              "
            >
              Batalkan publikasi
            </span>
          </div>
        </div>

        <ul
          class="navbar-nav navbar-nav-hover align-items-lg-center ml-lg-auto"
        >
          <li class="nav-item dropdown">
            <div class="nav-link" data-toggle="dropdown" role="button">
              <span class="nav-link-inner--text font-weight-bold text-primary"
                >Selamat Datang {{ storeAuth.user.full_name }}</span
              >
            </div>
            <div class="dropdown-menu nav-link">
              <router-link
                class="dropdown-item"
                :to="{ name: 'dashboardEvents', params: {} }"
              >
                Dashboard
              </router-link>
              <router-link
                class="dropdown-item"
                :to="{ name: 'editEvent', params: { e: eventId } }"
                v-if="storeProgressCreateEvent.isEdit"
              >
                <span class="ml-2"
                  ><i
                    class="fa fa-check text-warning mr-2"
                    v-if="storeProgressCreateEvent.progress.basicInfoStatus"
                  ></i>
                  <i class="fa fa-circle-o text-warning mr-2" v-else></i>Edit
                  Acara</span
                >
              </router-link>
              <router-link
                class="dropdown-item"
                :to="{ name: 'createDetail', params: { e: eventId } }"
                v-if="storeProgressCreateEvent.isEdit"
              >
                <span class="ml-2"
                  ><i
                    class="fa fa-check text-warning mr-2"
                    v-if="storeProgressCreateEvent.progress.detailStatus"
                  ></i>
                  <i class="fa fa-circle-o text-warning mr-2" v-else></i> Edit
                  Detail Acara</span
                >
              </router-link>
              <router-link
                class="dropdown-item"
                :to="{ name: 'createTicket', params: { e: eventId } }"
                v-if="storeProgressCreateEvent.isEdit"
              >
                <span class="ml-2"
                  ><i
                    class="fa fa-check text-warning mr-2"
                    v-if="storeProgressCreateEvent.progress.ticketStatus"
                  ></i>
                  <i class="fa fa-circle-o text-warning mr-2" v-else></i> Ticket
                </span>
              </router-link>
              <router-link
                class="dropdown-item"
                :to="{ name: 'customForm', params: { e: eventId } }"
                v-if="storeProgressCreateEvent.isEdit"
              >
                <span class="ml-2"
                  ><i
                    class="fa fa-check text-warning mr-2"
                    v-if="storeProgressCreateEvent.progress.customFormStatus"
                  ></i>
                  <i class="fa fa-circle-o text-warning mr-2" v-else></i> Kustom
                  Form</span
                >
              </router-link>
              <router-link
                class="dropdown-item"
                :to="{ name: 'scanAttendance', params: { e: eventId } }"
                v-if="storeProgressCreateEvent.isEdit"
              >
                <span class="ml-2">
                  <i class="fa fa-barcode text-success mr-2"></i> Pindai Peserta
                </span>
              </router-link>
              <router-link
                class="dropdown-item"
                :to="{ name: 'eventPurchases', params: { e: eventId } }"
                v-if="storeProgressCreateEvent.isEdit"  
              >
                <span class="ml-2">
                  <i class="fa fa-shopping-cart text-warning mr-2"></i> Pembelian
                </span>
              </router-link>
              <router-link
                class="dropdown-item"
                :to="{ name: 'myTicket', params: {} }"
              >
                Tiket Saya
              </router-link>
              <!-- <router-link
                class="dropdown-item"
                :to="{ name: 'listOrder', params: {} }"
              >
                Transaksi
              </router-link> -->
              <router-link
                class="dropdown-item"
                :to="{ name: 'organizerSetting', params: {} }"
              >
                Pengaturan Lebih Lanjut
              </router-link>
            </div>
          </li>
        </ul>
      </base-nav>
    </div>
  </header>
</template>
<script>
import BaseNav from "@/components/BaseNav";
import axios from "axios";
import { storeProgressCreateEvent } from "@/reactivities/storeProgressCreateEvent.js";
import { storeAuth } from "@/reactivities/storeAuth.js";
import { sweatalert } from "@/reactivities/sweatalert.js";
import debounce from "lodash.debounce";

export default {
  data: () => ({
    eventId: "",
    fullname: "",
    isEdit: false,
    eventStatus: "draft",
    urlEvent: null,
    basicInfoStatus: false,
    detailStatus: false,
    ticketStatus: false,
    customFormStatus: false,
    storeProgressCreateEvent,
    storeAuth,
    url: `${process.env.VUE_APP_API}/api/v1/dashboard/e/progress/`,
    urlUnpublishEvent: `${process.env.VUE_APP_API}/api/v1/dashboard/e/unpublish/`,
    authToken: "Bearer " + JSON.parse(localStorage.getItem("token")),
  }),
  computed: {
    loggedIn() {
      return JSON.parse(localStorage.getItem("token"));
    },
  },
  mounted() {
    this.checkIsEdited();
  },
  updated() {
    this.checkIsEdited();
  },
  components: {
    BaseNav,
  },
  methods: {
    getData: function () {
      storeProgressCreateEvent.isEdit = true;

      axios
        .get(this.url + this.eventId, {
          headers: {
            Authorization: this.authToken,
          },
        })
        .then(
          (res) => {
            if (res.data.success) {
              storeProgressCreateEvent.progress.basicInfoStatus =
                res.data.data.basicInfo;
              storeProgressCreateEvent.progress.detailStatus =
                res.data.data.detail;
              storeProgressCreateEvent.progress.ticketStatus =
                res.data.data.ticket;
              storeProgressCreateEvent.progress.customFormStatus =
                res.data.data.customForm;
              storeProgressCreateEvent.eventStatus = res.data.data.status;
              storeProgressCreateEvent.urlEvent = res.data.data.url_event;

              this.basicInfoStatus =
                storeProgressCreateEvent.progress.basicInfoStatus;
              this.detailStatus =
                storeProgressCreateEvent.progress.detailStatus;
              this.ticketStatus =
                storeProgressCreateEvent.progress.ticketStatus;
              this.customFormStatus =
                storeProgressCreateEvent.progress.customFormStatus;
              this.eventStatus = storeProgressCreateEvent.eventStatus;
              this.urlEvent = storeProgressCreateEvent.urlEvent;
            }
          },
          (err) => {
            if (err.response.data.message) {
              sweatalert.showAlert("error", err.response.data.message);
            } else {
              sweatalert.showAlert("error", "Maaf telah terjadi kesalahan");
            }
          }
        );
    },
    checkIsEdited: function () {
      this.eventId = this.$route.params.e;
      this.isEdit = this.eventId != null ? true : false;

      if (this.eventId) {
        this.getData();
      }

      if (!this.isEdit) {
        storeProgressCreateEvent.isEdit = false;
        storeProgressCreateEvent.progress.basicInfoStatus = false;
        storeProgressCreateEvent.progress.detailStatus = false;
        storeProgressCreateEvent.progress.ticketStatus = false;
        storeProgressCreateEvent.progress.customFormStatus = false;
        storeProgressCreateEvent.eventStatus = "draft";
        storeProgressCreateEvent.urlEvent = null;
      }
    },
    unPublishEvent: function () {
      if (confirm("Apakah anda yakin ingin membatalkan publikasi acara?")) {
        axios
          .get(this.urlUnpublishEvent + this.eventId, {
            headers: {
              Authorization: this.authToken,
            },
          })
          .then(
            (res) => {
              if (res.data.success) {
                sweatalert.showAlert("success", res.data.message);

                this.reloadPage();
              }
            },
            (err) => {
              if (err.response.data.message) {
                sweatalert.showAlert("error", err.response.data.message);
              } else {
                sweatalert.showAlert("error", "Maaf telah terjadi kesalahan");
              }
            }
          );
      }
    },
    reloadPage: debounce((e) => {
      location.reload();
    }, 1000),
  },
};
</script>
<style>
.max-width-full {
  max-width: 100% !important;
}
</style>
