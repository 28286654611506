import {
    reactive
} from 'vue'
import Swal from 'sweetalert2'

export const sweatalert = reactive({
    toast: Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    }),
    showAlert: function (type = "success", message = "pesan") {
        this.toast.fire({
            icon: type,
            title: message,
        });
    },
});