<template>
  <section class="pt-5 section section-shaped section-lg my-0">
    <div class="container container-lg">
      <div class="row">
        <div class="col-md-7">
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-8 col-8">
                  <router-link :to="{ name: 'landing', params: {} }">
                    <small class="text-warning"
                      ><i class="fa fa-angle-left"></i> Kembali</small
                    >
                  </router-link>

                  <base-input
                    inputClasses="input-search "
                    class="border-white mt-3"
                    style="box-shadow: none !important"
                    alternative
                    placeholder="Cari acara menarik disini."
                    addon-left-icon="fa fa-search"
                    v-model="querySearch"
                  >
                  </base-input>
                </div>

                <div class="col-md-1 col-1">
                  <div @click="removeQuery" class="mt-5 pt-1" v-if="querySearch">
                    <i class="fa fa-times cursor-pointer"></i>
                  </div>
                </div>
                <div class="col-md-1 col-1">
                  <base-button type="primary" @click="search" class="mt-5"
                    >Cari</base-button
                  >
                </div>
                <div class="col-md-12">
                  <hr class="my-2 border-top border-light" />
                </div>
              </div>
              <div class="p-2">
                <div class="row">
                  <div v-if="listEvent.length > 0">
                    <div
                      class="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3"
                      v-for="list in listEvent"
                      :key="list.id"
                    >
                      <router-link
                        :to="{
                          name: 'detailEvent',
                          params: { event: list.url_event },
                        }"
                      >
                        <div class="row">
                          <div class="col-md-8">
                            <h5 class="mb-0 font-weight-bold">
                              {{ list.title }}
                            </h5>
                            <p
                              class="
                                description
                                my-2
                                text-warning
                                font-weight-400
                              "
                            >
                              {{ list.formatted_from }}
                            </p>
                            <p class="text-default">{{ list.location }}</p>
                          </div>

                          <div class="col-md-4">
                            <img
                              :src="list.image_url"
                              class="w-100 mb-4"
                              alt=""
                            />
                          </div>
                        </div>
                      </router-link>
                    </div>
                  </div>
                  <div class="px-3 py-2" v-if="listEvent.length == 0">
                    <p class="heading text-warning">
                      Maaf acara tidak ditemukan.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-5">
          <GMapMap
            :center="center"
            :zoom="14"
            map-type-id="terrain"
            style="width: 100%; height: 90vh"
            :options="{
              draggable: true,
              zoomControl: true,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: false,
              fullscreenControl: false,
            }"
          >
            <GMapCluster>
              <GMapMarker
                :v-if="markers.positions"
                v-for="position in markers.positions"
                :key="position"
                :position="position"
                :clickable="true"
                :draggable="false"
              />
            </GMapCluster>
          </GMapMap>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from "axios";
import { storeAuth } from "@/reactivities/storeAuth.js";
import { sweatalert } from "@/reactivities/sweatalert.js";

export default {
  data: () => ({
    center: { lat: -7.0241344, lng: 110.4340521 },
    remeberme: true,
    submitted: false,
    storeAuth,
    querySearch: "",
    markers: {
      positions: [],
    },
    listEvent: [],
    url: `${process.env.VUE_APP_API}/api/v1/search`,
  }),
  computed: {},
  mounted() {
    this.getData();
  },
  methods: {
    getData: function () {
      let query = this.$route.query.query ?? " ";

      if (query != " ") {
        this.querySearch = query;
      }
      this.postQuery(query);
    },
    search: function () {
      let query = this.querySearch;

      this.postQuery(query);
    },
    postQuery: function (query) {
      axios.get(`${this.url}?query=${query}`).then(
        (res) => {
          if (res.data.data) {
            this.listEvent = res.data.data;

            if (!res.data.data.length) {
              this.markers.positions = [];
            } else {
              this.markers.positions = [];

              this.listEvent.forEach((element) => {
                let data = {
                  lat: parseFloat(element.latitude),
                  lng: parseFloat(element.longitude),
                };

                this.markers.positions.push(data);
              });
            }
          }
        },
        (err) => {
          if (err.response.data.message) {
            sweatalert.showAlert("error", err.response.data.message);
          } else {
            sweatalert.showAlert("error", "Maaf telah terjadi kesalahan");
          }
        }
      );
    },
    removeQuery: function () {
      this.querySearch = "";

      this.postQuery("");
    },
  },
};
</script>
<style>
.input-search {
  font-size: 1.75rem !important;
}
</style>