import firebase from 'firebase/app';
import axios from "axios";
let firebaseInit = {
    methods: {
        firebaseInit: function () {
            const firebaseConfig = {
                apiKey: "AIzaSyCoMNnTaR3fwQJHDUq7uWA4Ck8l-t5dEEc",
                authDomain: "kiara-event.firebaseapp.com",
                projectId: "kiara-event",
                storageBucket: "kiara-event.appspot.com",
                messagingSenderId: "266714720837",
                appId: "1:266714720837:web:432e9cf0452dbe6849512c",
                measurementId: "G-PS9KJVM9EQ"
            };

            firebase.initializeApp(firebaseConfig);
            if ("Notification" in window && firebase.messaging.isSupported()) {
                const messaging = firebase.messaging();
                try {
                    messaging
                        .getToken({
                            vapidKey: "BDtkFLqAqQe19TRlNyhjT6rmcngxgtQXZO2-zJGOuJsWoMRKu08xpvAXsGmZzqQ9MLiZpTqz6qexC-F2zkAopxg",
                        })
                        .then((currentToken) => {
                            if (currentToken) {
                                this.sendTokenToServer(currentToken);
                            } else {
                                // console.warn("Failed to get token.");
                            }
                        })
                        .catch((err) => {
                            // console.log(
                            //     "An error occurred while retrieving token. ",
                            //     err
                            // );
                            this.setTokenSentToServer(false);
                        });
                } catch (e) {
                    // console.log(e);
                }
                messaging.onMessage((payload) => {
                    // console.log("Message received. firebase.js ", payload);
                    new Notification(
                        payload.notification.title,
                        payload.notification
                    );
                });
            }
        },
        isTokenSentToServer: function (currentToken) {
            return (
                window.localStorage.getItem("sentFirebaseMessagingToken") ===
                currentToken
            );
        },
        setTokenSentToServer: function (currentToken) {
            window.localStorage.setItem(
                "sentFirebaseMessagingToken",
                currentToken ? currentToken : ""
            );
        },
        sendTokenToServer: function (currentToken) {
            if (!this.isTokenSentToServer(currentToken)) {
                axios
                    .post("rest/device/token", {
                        token: currentToken
                    })
                    .then((data) => {
                        if (data.data.status) {
                            this.setTokenSentToServer(currentToken);
                        }
                    });
            }
        },
    },
};
export default {
    firebaseInit,
};