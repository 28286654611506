import Vue from "vue";
import Router from "vue-router";
import AppHeader from "./layout/AppHeader";
import AppHeaderNone from "./layout/AppHeaderNone";
import AppFooter from "./layout/AppFooter";
import DashboardHeader from "./layout/DashboardHeader";
import Landing from "./views/Landing.vue";
import Login from "./views/Login.vue";
import Register from "./views/Register.vue";
import DetailEvent from "./views/DetailEvent.vue";
import SearchPage from "./views/SearchPage.vue";
import DashboardEvents from "./views/dashboard/DashboardEvents.vue";
import CreateEvent from "./views/dashboard/CreateEvent.vue";
import CreateDetail from "./views/dashboard/CreateDetail.vue";
import CreateTicket from "./views/dashboard/CreateTicket.vue";
import EventPublish from "./views/dashboard/EventPublish.vue";
import CustomForm from "./views/dashboard/CustomForm.vue";
import ListOrder from "./views/dashboard/ListOrder.vue";
import DetailOrder from "./views/dashboard/DetailOrder.vue";
import ScanAttendance from "./views/dashboard/ScanAttendance.vue";
import MyTicket from "./views/dashboard/MyTicket.vue";
import DetailTicket from "./views/dashboard/DetailTicket.vue";
import OrganizerSetting from "./views/dashboard/OrganizerSetting.vue";
import ForgotPassword from "./views/ForgotPassword.vue";
import ConfirmationCode from "./views/ConfirmationCode.vue";
import ChangePassword from "./views/ChangePassword.vue";
import NewScanAttendance from "./views/dashboard/NewScanAttendance";
import ResultScan from "./views/dashboard/resultScan";
import TestingTag from "./views/dashboard/testingtag.vue";
import EventPurchasing from "./views/dashboard/EventPurchasing";

Vue.use(Router);

export default new Router({
  linkExactActiveClass: "active",
  routes: [{
      path: "/",
      name: "landing",
      components: {
        header: AppHeader,
        default: Landing,
        footer: AppFooter
      }
    },
    {
      path: "/login",
      name: "login",
      components: {
        header: AppHeader,
        default: Login,
        footer: AppFooter
      }
    },
    {
      path: "/register",
      name: "register",
      components: {
        header: AppHeader,
        default: Register,
        footer: AppFooter
      },
    },
    {
      path: "/forgot-password",
      name: "forgotPassword",
      components: {
        header: AppHeader,
        default: ForgotPassword,
        footer: AppFooter
      },
    },
    {
      path: "/confirmation-code",
      name: "confirmationCode",
      components: {
        header: AppHeader,
        default: ConfirmationCode,
        footer: AppFooter
      },
    },
    {
      path: "/change-password",
      name: "changePassword",
      components: {
        header: AppHeader,
        default: ChangePassword,
        footer: AppFooter
      },
    },
    {
      path: "/search",
      name: "searchPage",
      components: {
        header: AppHeaderNone,
        default: SearchPage,
        footer: AppFooter
      },
    },
    {
      path: "/e/:event",
      name: "detailEvent",
      components: {
        header: AppHeader,
        default: DetailEvent,
        footer: AppFooter
      }
    },
    {
      path: "/payment",
      name: "payment",
      components: {
        header: AppHeader,
        default: DetailEvent,
        footer: AppFooter
      }
    },
    {
      path: "/dashboard/event",
      name: "dashboardEvents",
      components: {
        header: DashboardHeader,
        default: DashboardEvents,
        footer: AppFooter
      },
    },
    {
      path: "/dashboard/event/create/basicinfo",
      name: "createEvent",
      components: {
        header: DashboardHeader,
        default: CreateEvent,
        footer: AppFooter
      },
    },
    {
      path: "/dashboard/event/create/basicinfo/:e",
      name: "editEvent",
      components: {
        header: DashboardHeader,
        default: CreateEvent,
        footer: AppFooter
      },
    },
    {
      path: "/dashboard/event/create/detail/:e",
      name: "createDetail",
      components: {
        header: DashboardHeader,
        default: CreateDetail,
        footer: AppFooter
      },
    },
    {
      path: "/dashboard/event/create/ticket/:e",
      name: "createTicket",
      components: {
        header: DashboardHeader,
        default: CreateTicket,
        footer: AppFooter
      },
    },
    {
      path: "/dashboard/event/create/publish/:e",
      name: "createPublish",
      components: {
        header: DashboardHeader,
        default: EventPublish,
        footer: AppFooter
      },
    },
    {
      path: "/dashboard/event/custom-form/:e",
      name: "customForm",
      components: {
        header: DashboardHeader,
        default: CustomForm,
        footer: AppFooter
      },
    },
    {
      path: "/dashboard/event/scan/:e",
      name: "scanAttendance",
      components: {
        header: DashboardHeader,
        default: ScanAttendance,
        footer: AppFooter
      },
    },
    {
      path: "/dashboard/event/newscan/:e",
      name: "newScanAttendance",
      components: {
        header: AppHeaderNone,
        default: NewScanAttendance,
        footer: AppFooter
      },
    },
    {
      path: "/dashboard/event/resultscan",
      name: "resultScan",
      components: {
        header: AppHeaderNone,
        default: ResultScan,
        footer: AppFooter
    },
    },
    {
      path: "/dashboard/event/purchases/:e",
      name: "eventPurchases",
      components: {
        header: DashboardHeader,
        default: EventPurchasing,
        footer: AppFooter
      },
    },
    {
      path: "/dashboard/event/order",
      name: "listOrder",
      components: {
        header: DashboardHeader,
        default: ListOrder,
        footer: AppFooter
      },
    },
    {
      path: "/dashboard/event/order/detail/:orderId",
      name: "detailOrder",
      components: {
        header: DashboardHeader,
        default: DetailOrder,
        footer: AppFooter
      },
    },
    {
      path: "/dashboard/ticket",
      name: "myTicket",
      components: {
        header: DashboardHeader,
        default: MyTicket,
        footer: AppFooter
      },
    },
    {
      path: "/dashboard/ticket/detail/:orderId",
      name: "detailTicket",
      components: {
        header: DashboardHeader,
        default: DetailTicket,
        footer: AppFooter
      },
    },
    {
      path: "/dashboard/setting",
      name: "organizerSetting",
      components: {
        header: DashboardHeader,
        default: OrganizerSetting,
        footer: AppFooter
      },
    },
    // {
    //   path: "/dashboard/testingtag",
    //   name: "testingTag",
    //   components: {
    //     default: TestingTag,
    //   },
    // },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        offset: {
          y: 32,
        },
      };
    }

    return savedPosition ?
      savedPosition : {
        x: 0,
        y: 0
      };
  },
});