<template>
  <div id="app">
    <router-view name="header"></router-view>
    <main>
      <router-view />
    </main>
    <router-view name="footer"></router-view>
  </div>
</template>
<script>
import { storeAuth } from "@/reactivities/storeAuth.js";
import Swal from "sweetalert2";
import firebase from "./firebase";
import { sweatalert } from "@/reactivities/sweatalert.js";

export default {
  created() {
    try {
      firebase
        .messaging()
        .requestPermission()
        .then(() => {
          /* eslint-disable */
          console.log("Notification permission granted");
          /* eslint-enable */
          firebase
            .messaging()
            .getToken()
            .then((token) => {
              this.receiveMessage();
            });
        })
        .catch((err) => {
          /* eslint-disable */
          console.log("Unable to get token ", err);
          /* eslint-enable */
        });
    } catch (e) {
      /* eslint-disable */
      console.log(e);
      /* eslint-enable */
    }
  },
  data: () => ({
    storeAuth,

    toast: Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 10000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    }),
  }),
  components: {},
  mounted: function () {
    this.checkAuth();
  },
  methods: {
    checkAuth: function () {
      if (JSON.parse(localStorage.getItem("token"))) {
        (storeAuth.isLogin = true),
          (storeAuth.user.full_name = JSON.parse(
            localStorage.getItem("user")
          ).full_name);
      }
    },
    receiveMessage() {
      try {
        firebase.messaging().onMessage((payload) => {
          sweatalert.showAlert("success", payload.notification.body);
        });
      } catch (e) {
        window.console.log("error ", e);
      }
    },
  },
};
</script>
