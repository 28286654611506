<template>
  <div>
    <section class="section section-shaped section-lg my-0">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="mb-4">
              <router-link :to="{ name: 'createEvent', params: {} }">
                <base-button class="btn-1 float-right" type="primary"
                  >Buat Acara Baru</base-button
                >
              </router-link>
              <h3>Daftar Acara</h3>
            </div>
            <div class="mt-3">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col" class="col-md-8">Nama Acara</th>
                    <th scope="col" class="col-md-2 text-center">Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="list in listEvent" :key="list.id">
                    <td>
                      <div class="row">
                        <div class="col-md-1">
                          <span class="heading text-primary">{{
                            list.from_only_month
                          }}</span>
                          <br />
                          <h5 class="">{{ list.from_only_date }}</h5>
                        </div>
                        <div class="col-md-2">
                          <img
                            :src="list.image_url"
                            class="img-fluid rounded w-100"
                            alt=""
                          />
                        </div>
                        <div class="col-md-9">
                          {{ list.title }}
                          <div class="description mt-2">
                            <p>
                              {{ list.location }} <br />
                              {{ list.time }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="row">
                        <div class="col-md-8">
                          <dd
                            class="badge badge-success"
                            v-if="list.status == 'publish'"
                          >
                            {{ list.status }}
                          </dd>
                          <dd
                            class="badge badge-warning"
                            v-if="list.status == 'draft'"
                          >
                            {{ list.status }}
                          </dd>
                          <dd
                            class="badge badge-danger"
                            v-if="list.status == 'deleted'"
                          >
                            {{ list.status }}
                          </dd>
                        </div>
                        <div class="col-md-4">
                          <router-link
                            :to="{
                              name: 'editEvent',
                              params: { e: list.id },
                            }"
                          >
                            <i class="fa fa-eye mr-2"></i>
                          </router-link>
                          <i
                            class="fa fa-trash text-danger cursor-pointer"
                            @click="deleteEvent(list.id)"
                          ></i>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="listEvent.length == 0">
                    <p class="my-2">
                      Acara anda masih kosong. Buat acara anda disini
                      <router-link
                        class="text-warning"
                        :to="{ name: 'createEvent', params: {} }"
                      >
                        buat acara baru.
                      </router-link>
                    </p>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import axios from "axios";
import { sweatalert } from "@/reactivities/sweatalert.js";

export default {
  components: {},
  mounted() {
    this.getData();
  },
  data() {
    return {
      urlGetEvent: `${process.env.VUE_APP_API}/api/v1/dashboard/events`,
      urlDeleteEvent: `${process.env.VUE_APP_API}/api/v1/dashboard/e/event/delete/`,
      authToken: "Bearer " + JSON.parse(localStorage.getItem("token")),
      listEvent: [],
    };
  },
  methods: {
    getData: function () {
      axios
        .get(this.urlGetEvent, {
          headers: { Authorization: this.authToken },
        })
        .then(
          (res) => {
            this.listEvent = res.data.data;
          },
          (err) => {
            if (err.response.data.message) {
              sweatalert.showAlert("error", err.response.data.message);
            } else {
              sweatalert.showAlert("error", "Maaf telah terjadi kesalahan");
            }
          }
        );
    },
    deleteEvent: function (eventId) {
      if (
        confirm(
          "Anda yakin ingin menghapus acara ini? Ini tidak dapat dibatalkan."
        )
      ) {
        axios
          .get(this.urlDeleteEvent + eventId, {
            headers: { Authorization: this.authToken },
          })
          .then(
            (res) => {
              this.listEvent = res.data.data;
              sweatalert.showAlert("success", res.data.message);

              this.$router.push({
                name: "dashboardEvents",
              });
            },
            (err) => {
              if (err.response.data.message) {
                sweatalert.showAlert("error", err.response.data.message);
              } else {
                sweatalert.showAlert("error", "Maaf telah terjadi kesalahan");
              }
            }
          );
      }
    },
  },
};
</script>
<style>
</style>
