<template>
  <div>
    <section class="section section-shaped section-lg my-0">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <router-link
              class="text-light"
              :to="{ name: 'scanAttendance', params: { e: eventId } }"
            >
              <p class="text-warning">
                <i class="fa fa-chevron-left mr-1"></i> Kembali
              </p>
            </router-link>
            <div class="mb-5">
              <div class="pull-right">
                <router-link
                  :to="{ name: 'resultScan', params: {} }"
                  target="_blank"
                  >Result</router-link
                >
              </div>
              <h3>Pindai Peserta</h3>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div
              id="reader"
              class="card shadow-lg p-3 mb-5 bg-white rounded w-100"
            ></div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { Html5QrcodeScanner } from "html5-qrcode";
import { qrcodeCameraStatus } from "@/reactivities/qrcodeCameraStatus.js";

import axios from "axios";

export default {
  components: {},
  data() {
    return {
      modalAttendanceInfo: true,
      eventId: this.$route.params.e,
      event: "",
      participants: "",
      alreadyScan: false,
      urlScan: `${process.env.VUE_APP_API}/api/v1/dashboard/e/scan`,
      authToken: "Bearer " + JSON.parse(localStorage.getItem("token")),
      currentParticipant: {
        nama: "...",
        waktu: "...",
      },
      bc: "",
    };
  },
  unmounted() {
    qrcodeCameraStatus.html5QrcodeScanner.clear();
  },
  created() {
    // Connection to a broadcast channel
    this.bc = new BroadcastChannel("mykiara_event");
    // this.postBroadcastMessage();
  },
  mounted() {
    qrcodeCameraStatus.html5QrcodeScanner = new Html5QrcodeScanner(
      "reader",
      { fps: 10 },
      /* verbose= */ false
    );
    qrcodeCameraStatus.html5QrcodeScanner.render(
      this.onScanSuccess,
      this.onScanFailure
    );
  },
  methods: {
    postBroadcastMessage: function (isAlreadyAtt) {
      let msg = {
        type: "scan_attendance",
        data: {
          nama_lengkap_primary: this.currentParticipant.nama,
          nama_lengkap_secondary: "...",
          waktu: this.currentParticipant.waktu,
        },
        isAlreadyAttendance: isAlreadyAtt,
      };

      this.bc.postMessage(msg);
    },
    scanSuccess: function ($pur_id) {
      let data = {
        event_id: this.eventId,
        purchased_id: $pur_id,
      };

      axios
        .post(this.urlScan, data, {
          headers: { Authorization: this.authToken },
        })
        .then(
          (res) => {
            if (res.data.success) {
              this.event = res.data.data.event;
              this.participants = res.data.data.participants;

              let result = res.data.data.participants.filter((obj) => {
                return obj.id == $pur_id;
              });

              if (result) {
                this.currentParticipant.nama = result[0].participant.full_name;
                this.currentParticipant.waktu = result[0].attended_at;
              }

              this.postBroadcastMessage(false);
            } else {
              this.postBroadcastMessage(true);
            }

            setTimeout(() => {
              this.alreadyScan = false;
            }, 2000);
          },
          (err) => {
            if (err.response.data.message) {
              sweatalert.showAlert("error", err.response.data.message);
            } else {
              sweatalert.showAlert("error", "Maaf telah terjadi kesalahan");
            }
          }
        );
    },
    onScanSuccess: function (decodedText, decodedResult) {
      // handle the scanned code as you like, for example:

      if (this.alreadyScan == false) {
        this.alreadyScan = true;

        this.alertSuccessScan();

        this.scanSuccess(decodedText);
      }
    },
    onScanFailure: function (decodedText, decodedResult) {},
    alertSuccessScan: function () {
      var audio = new Audio("audio/notification.wav"); // path to file

      audio.play();
    },
  },
};
</script>
<style>
</style>
