import {
    reactive
} from 'vue'

export const storeProgressCreateEvent = reactive({
    eventId: null,
    isEdit: false,
    eventStatus: 'draft',
    urlEvent: null,
    progress: {
        basicInfoStatus: false,
        detailStatus: false,
        ticketStatus: false,
        customFormStatus: false
    },
});