<template>
  <header class="header-global">
    <base-nav
      class="navbar-main mt-3"
      transparent
      type=""
      effect="light"
      expand
      title="My Kiara Event"
    >
      <template v-slot:contentHeader>
        <div class="col-6 collapse-brand">
          <router-link to="/"> My Kiara Event</router-link>
        </div>
      </template>

      <ul class="navbar-nav navbar-nav-hover align-items-lg-center ml-lg-auto">
        <li class="nav-item dropdown" v-if="!storeAuth.isLogin">
          <router-link class="user-icon" to="/login">
            <i class="fa fa-user-o mr-1"></i> Masuk
          </router-link>
        </li>
        <li class="nav-item dropdown" v-if="storeAuth.isLogin">
          <a href="#" class="nav-link" data-toggle="dropdown" role="button">
            <span class="nav-link-inner--text font-weight-bold"
              >Selamat Datang {{ storeAuth.user.full_name }}</span
            >
          </a>
          <div class="dropdown-menu nav-link">
            <router-link
              :to="{ name: 'dashboardEvents', params: {} }"
              class="dropdown-item"
              >Dashboard</router-link
            >
            <router-link
              :to="{ name: 'createEvent', params: {} }"
              class="dropdown-item"
              >Buat Acara</router-link
            >
            <router-link
              :to="{ name: 'myTicket', params: {} }"
              class="dropdown-item"
              >Manajemen Tiket</router-link
            >
            <p class="dropdown-item cursor-pointer" @click="logout">Logout</p>
          </div>
        </li>
      </ul>
    </base-nav>
  </header>
</template>
<script>
import BaseNav from "@/components/BaseNav";
import axios from "axios";
import { storeAuth } from "@/reactivities/storeAuth.js";
import { sweatalert } from "@/reactivities/sweatalert.js";
import debounce from "lodash.debounce";

export default {
  data: () => ({
    email: "",
    password: "",
    remeberme: true,
    submitted: false,
    full_name: "",
    isLogin: false,
    storeAuth,
    url: `${process.env.VUE_APP_API}/api/v1/logout`,
    authToken: "",
  }),
  computed: {
    loggedIn() {
      return JSON.parse(localStorage.getItem("token"));
    },
  },
  mounted() {
    if (this.loggedIn) {
      storeAuth.isLogin = true;
      storeAuth.full_name = JSON.parse(localStorage.getItem("user")).full_name;
      storeAuth.authToken =
        "Bearer " + JSON.parse(localStorage.getItem("token"));
    }
  },
  components: {
    BaseNav,
  },
  methods: {
    closeMenu: function () {
      this.$refs.children.toggled = false;
    },
    logout: function () {
      if (JSON.parse(localStorage.getItem("token"))) {
        axios
          .get(this.url, {
            headers: { Authorization: storeAuth.authToken },
          })
          .then(
            (res) => {
              if (res.data.success) {
                sweatalert.showAlert("success", res.data.message);

                this.logoutUser();
              }
            },
            (err) => {
              if (err.response.data.message) {
                sweatalert.showAlert("error", err.response.data.message);

                if (err.response.status == 401) {
                  this.logoutUser();
                }
              } else {
                sweatalert.showAlert("error", "Maaf telah terjadi kesalahan");
              }
            }
          );
      }
    },
    logoutUser: debounce((e) => {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      storeAuth.isLogin = false;
      storeAuth.user.full_name = null;
      this.$router.push({ name: "login" });
    }, 3000),
  },
};
</script>

<style>
@media screen and (min-width: 900px) {
  .user-icon {
    color: #fff;
  }
  .icon-navbar {
    color: #fff;
  }
}

@media screen and (max-width: 768px) {
  .user-icon {
    color: #333;
  }

}
</style>
