<template>
  <nav
    class="navbar"
    :class="[
      { 'navbar-expand-lg': expand },
      { [`navbar-${effect}`]: effect },
      { 'navbar-transparent': transparent },
      { [`bg-${type}`]: type },
      { rounded: round },
    ]"
  >
    <div class="container" :class="classContainer">
      <slot name="container-pre"></slot>
      <slot name="brand">
        <a class="navbar-brand" href="#" @click.prevent="onTitleClick">
          {{ title }}
        </a>

        <router-link
          :v-slot="brand"
          class="navbar-brand mr-lg-5"
          :to="{ name: 'searchPage', query: {} }"
        >
          <i class="fa fa-search mr-2"></i> Cari acara disini
        </router-link>
      </slot>
      <div @click="toggled = !toggled">
        <navbar-toggle-button :toggled="toggled" :target="contentId">
        </navbar-toggle-button>
      </div>
      <slot name="container-after"></slot>

      <div
        class="collapse navbar-collapse"
        :class="{ show: toggled }"
        :id="contentId"
      >
        <div class="navbar-collapse-header">
          <div class="row">
            <slot name="contentHeader"> </slot>
            <div class="col-6 collapse-close fa fa-times" @click="closeMenu"></div>
          </div>
        </div>
        <slot :close-menu="closeMenu"></slot>
      </div>
    </div>
  </nav>
</template>
<script>
import NavbarToggleButton from "./NavbarToggleButton";

export default {
  name: "base-nav",
  components: {
    NavbarToggleButton,
  },
  props: {
    type: {
      type: String,
      default: "primary",
      description: "Navbar type (e.g default, primary etc)",
    },
    title: {
      type: String,
      default: "",
      description: "Title of navbar",
    },
    contentId: {
      type: [String, Number],
      default: Math.random().toString(),
      description:
        "Explicit id for the menu. By default it's a generated random number",
    },
    effect: {
      type: String,
      default: "dark",
      description: "Effect of the navbar (light|dark)",
    },
    round: {
      type: Boolean,
      default: false,
      description: "Whether nav has rounded corners",
    },
    transparent: {
      type: Boolean,
      default: false,
      description: "Whether navbar is transparent",
    },
    expand: {
      type: Boolean,
      default: false,
      description: "Whether navbar should contain `navbar-expand-lg` class",
    },
    classContainer: {
      type: String,
      default: "",
      description: "Class of container",
    },
  },
  data() {
    return {
      toggled: false,
    };
  },
  methods: {
    onTitleClick(evt) {
      this.$router.push({ name: "landing" });
    },
    closeMenu() {
      this.toggled = false;
    },
  },
  mounted() {},
};
</script>
<style>
</style>
