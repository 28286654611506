<template>
  <div>
    <div class="position-relative">
      <!-- shape Hero -->
      <section class="section-shaped my-0">
        <div class="shape shape-style-1 shape-default shape-skew">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div class="container shape-container d-flex">
          <div class="col px-0">
            <div class="row">
              <div class="col-lg-6">
                <h1 class="display-3 text-white">
                  My Kiara Event
                  <span>Luncurkan acara impian Anda dengan bantuan kami</span>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- 1st Hero Variation -->
    </div>

    <!-- awesome feature -->
    <section class="section section-lg">
      <div class="container">
        <div class="row row-grid">
          <div class="col-md-12">
            <h3 class="text-primary font-weight-light">Acara Di Sekitarmu</h3>
          </div>

          <template v-if="isLoading">
            <div class="col-md-12 mt-4">
              <h1><i class="fa fa-search"></i></h1>
              <h5 class="mt-4">Sedang Mencari acara yang cocok untukmu...</h5>
            </div>
          </template>
          <template v-else>
            <div
              class="col-sm-12 col-md-6 col-lg-4 col-xl-3 mt-3"
              v-for="list in listEvent"
              :key="list.id"
            >
              <card class="border-0" hover shadow body-classes="pt-2 pb-3">
                <img :src="list.image_url" class="w-100 mb-4" alt="" />
                <h6 class="text-primary text-uppercase">{{ list.title }}</h6>
                <p class="description mt-3">
                  {{ list.summary }}
                </p>
                <div>
                  <badge
                    type="primary"
                    rounded
                    v-for="tag in list.tags"
                    :key="tag.id"
                    >{{ tag.name }}</badge
                  >
                </div>
                <router-link
                  :to="{
                    name: 'detailEvent',
                    params: { event: list.url_event },
                  }"
                >
                  <base-button type="primary" class="mt-4">
                    Lihat Acara
                  </base-button>
                </router-link>
              </card>
            </div>
          </template>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import axios from "axios";
import { sweatalert } from "@/reactivities/sweatalert.js";

export default {
  name: "home",
  components: {},
  created() {
    this.getData();
  },
  data() {
    return {
      listEvent: [],
      isLoading: true,
      url: `${process.env.VUE_APP_API}/api/v1/events`,
    };
  },
  methods: {
    getData: function () {
      axios.get(this.url).then(
        (res) => {
          if (res.data.data) {
            this.listEvent = res.data.data;

            this.isLoading = false;
          }
        },
        (err) => {
          if (err.response.data.message) {
            sweatalert.showAlert("error", err.response.data.message);
          } else {
            sweatalert.showAlert("error", "Maaf telah terjadi kesalahan");
          }
        }
      );
    },
  },
};
</script>
