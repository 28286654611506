<template>
  <section class="section section-shaped section-lg my-0">
    <div class="base-notification" v-if="notification.status">
      <base-alert :type="notification.type" icon="ni ni-like-2" dismissible>
        <span :v-slot="text" class="text-capitalize"
          ><strong>{{ notification.type }}!</strong>
          {{ notification.message }}</span
        >
      </base-alert>
    </div>
    <div class="shape shape-style-1 bg-gradient-default">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
    <div class="container pt-lg-md">
      <div class="row justify-content-center">
        <div class="col-lg-5">
          <card
            type="secondary"
            shadow
            header-classes="bg-white pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0"
          >
            <div>
              <div class="text-center text-muted mb-4">
                <h1 class="heading">Buat Akun Baru</h1>
              </div>
              <form role="form" @submit.prevent="register">
                <base-input
                  alternative
                  class="mb-3"
                  placeholder="Nama Lengkap"
                  v-model="name"
                  :required="true"
                  addon-left-icon="ni ni-hat-3"
                >
                </base-input>
                <base-input
                  alternative
                  class="mb-3"
                  placeholder="Email"
                  v-model="email"
                  type="email"
                  :required="true"
                  addon-left-icon="ni ni-email-83"
                >
                </base-input>
                <base-input
                  alternative
                  type="password"
                  v-model="password"
                  placeholder="Password"
                  :required="true"
                  addon-left-icon="ni ni-lock-circle-open"
                >
                </base-input>
                <!-- <base-checkbox>
                  <span
                    >I agree with the
                    <a href="#">Privacy Policy</a>
                  </span>
                </base-checkbox> -->
                <div class="text-center">
                  <base-button nativeType="submit" type="primary" class="my-4"
                    >Buat Akun</base-button
                  >
                </div>
              </form>
            </div>
          </card>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from "axios";
import { sweatalert } from "@/reactivities/sweatalert.js";

export default {
  data: () => ({
    notification: {
      status: false,
      type: "Success",
      message: "Login berhasil!",
    },
    name: "",
    email: "",
    password: "",
    urlPost: `${process.env.VUE_APP_API}/api/v1/register`,
  }),
  computed: {
    loggedIn() {
      return JSON.parse(localStorage.getItem("token"));
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push({ name: "landing" });
    }
  },
  methods: {
    register: function () {
      axios
        .post(this.urlPost, {
          name: this.name,
          email: this.email,
          password: this.password,
        })
        .then(
          (res) => {
            if (res) {
              sweatalert.showAlert("success", res.data.message);

              setTimeout(() => this.$router.push({ name: "login" }), 2000);
            } else {
              if (err.response.data.message) {
                sweatalert.showAlert("error", err.response.data.message);
              } else {
                sweatalert.showAlert("error", "Maaf telah terjadi kesalahan");
              }
            }
          },
          (err) => {
            if (err.response.data.message) {
              sweatalert.showAlert("error", err.response.data.message);
            } else {
              sweatalert.showAlert("error", "Maaf telah terjadi kesalahan");
            }
          }
        );
    },
  },
};
</script>
<style>
</style>
