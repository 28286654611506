<template>
  <div>
    <section class="section section-shaped section-lg my-0">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="mb-4">
              <h3 class="lead text-muted mb-0">{{ fullname }}</h3>
              <h1 class="font-weight-bold">Pengaturan Lanjutan</h1>
              <p>
                Ini adalah gambar pertama yang akan dilihat peserta di bagian
                atas profil Anda. Gunakan gambar persegi berkualitas tinggi.
              </p>
            </div>
          </div>
        </div>

        <modal
          :show="modals.modalCreate.status"
          body-classes="p-0"
          modal-classes="modal-dialog-centered modal-lg"
        >
          <div class="row">
            <!-- Ticket Section -->
            <div class="col-md-12" style="padding-right: 0px !important">
              <card
                type="secondary"
                shadow
                header-classes="bg-white"
                body-classes="px-lg-1 py-lg-3 bg-white"
                footer-classes="bg-white"
                class="border-0"
              >
                <div class="container">
                  <div class="row">
                    <div class="col-md-12">
                      <form role="form" @submit.prevent="savePayment">
                        <header class="text-center">
                          Buat Metode Pembayaran
                        </header>

                        <label for="">Pilih nama bank</label>
                        <v-select
                          label="name"
                          class="mb-3"
                          :filterable="false"
                          :options="options"
                          @search="onSearch"
                          v-model="modals.modalCreate.data.name"
                          :reduce="(options) => options.name"
                          :clearable="false"
                        >
                        </v-select>

                        <label for="" class="mb-0">Nomer Rekening</label>
                        <base-input
                          alternative
                          class="border-white mb-0"
                          inputClasses="p-0"
                          type="number"
                          style="box-shadow: none !important"
                          placeholder="Nama Pemilik Rekening"
                          :required="true"
                          v-model="modals.modalCreate.data.bankNo"
                        >
                        </base-input>
                        <hr class="m-0 mt-3 mb-3" />

                        <label for="" class="mb-0">Nama Pemilik Rekening</label>
                        <base-input
                          alternative
                          class="border-white mb-0"
                          inputClasses="p-0"
                          type="text"
                          style="box-shadow: none !important"
                          placeholder="Nama Pemilik Rekening"
                          :required="true"
                          v-model="modals.modalCreate.data.bankName"
                        >
                        </base-input>
                        <hr class="m-0 mt-3 mb-3" />

                        <base-button
                          type="secondary"
                          @click="modals.modalCreate.status = false"
                        >
                          Batal
                        </base-button>

                        <base-button type="primary" nativeType="submit">
                          Simpan Perubahan
                        </base-button>
                      </form>
                    </div>
                  </div>
                </div>
              </card>
            </div>
          </div>
        </modal>

        <modal
          :show="modals.modalEdit.status"
          body-classes="p-0"
          modal-classes="modal-dialog-centered modal-lg"
        >
          <div class="row">
            <!-- Ticket Section -->
            <div class="col-md-12" style="padding-right: 0px !important">
              <card
                type="secondary"
                shadow
                header-classes="bg-white"
                body-classes="px-lg-1 py-lg-3 bg-white"
                footer-classes="bg-white"
                class="border-0"
              >
                <div>
                  <div class="container">
                    <div class="row">
                      <div
                        class="col-md-12"
                        style="padding-right: 0px !important"
                      >
                        <form role="form" @submit.prevent="updatePayment">
                          <header class="text-center">
                            Buat Payment Metode
                          </header>

                          <label for="">Pilih nama bank</label>
                          <v-select
                            label="name"
                            :filterable="false"
                            :options="options"
                            @search="onSearch"
                            class="mb-3"
                            v-model="modals.modalEdit.data.name"
                            :reduce="(options) => options.name"
                            :clearable="false"
                          >
                          </v-select>

                          <label for="" class="mb-0">Nomer Rekening</label>
                          <base-input
                            alternative
                            class="border-white mb-0"
                            inputClasses="p-0"
                            type="number"
                            style="box-shadow: none !important"
                            placeholder="Nama Pemilik Rekening"
                            :required="true"
                            v-model="modals.modalEdit.data.bankNo"
                          >
                          </base-input>
                          <hr class="m-0 mt-3 mb-3" />

                          <label for="" class="mb-0"
                            >Nama Pemilik Rekening</label
                          >
                          <base-input
                            alternative
                            class="border-white mb-0"
                            inputClasses="p-0"
                            type="text"
                            style="box-shadow: none !important"
                            placeholder="Nama Pemilik Rekening"
                            :required="true"
                            v-model="modals.modalEdit.data.bankName"
                          >
                          </base-input>
                          <hr class="m-0 mt-3 mb-3" />

                          <base-button
                            type="secondary"
                            @click="modals.modalEdit.status = false"
                          >
                            Batal
                          </base-button>

                          <base-button type="primary" nativeType="submit">
                            Simpan Perubahan
                          </base-button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </card>
            </div>
          </div>
        </modal>

        <div class="row">
          <div class="col-md-12 col-lg-8 col-12 col-sm-12">
            <tabs fill class="flex-column flex-md-row">
              <card class="border-0">
                <tab-pane title="Organizer">
                  <form role="form" @submit.prevent="saveOrganizer">
                    <!-- <h4 class="font-weight-bold">Gambar profil</h4>
                    <p>
                      Ini adalah gambar profil. Gunakan gambar persegi
                      berkualitas tinggi.
                    </p>
                    <base-input
                      id="image-profile"
                      class="d-none"
                      type="file"
                      accept="image/png, image/jpeg, image/bmp"
                      ref="fileImageCover"
                      @change="onChangeImageProfile"
                    >
                    </base-input>
                    <div v-if="!urlImageProfile">
                      <div
                        class="card p-5 card-image-profile shadow my-3"
                        @click="chooseFilesImageProfile"
                      >
                        <div class="text-center">
                          <i class="fa fa-image text-primary icon-image"></i>
                          <p class="heading mb-0">
                            Klik untuk menambahkan Gambar Profile
                          </p>
                          <p>JPEG atau PNG, tidak lebih besar dari 10MB.</p>
                        </div>
                      </div>
                    </div>
                    <div v-if="urlImageProfile">
                      <img
                        id="image-cover"
                        class=""
                        :src="urlImageProfile"
                        alt=""
                      />

                      <base-button
                        type="primary"
                        @click="chooseFilesImageProfile"
                        class="my-5"
                        >Ganti Gambar</base-button
                      >
                    </div>

                    <h4 class="font-weight-bold">Gambar Cover</h4>
                    <p>
                      Ini adalah gambar cover yang akan dilihat peserta di
                      bagian atas profil Anda. Gunakan gambar persegi
                      berkualitas tinggi.
                    </p>
                    <base-input
                      id="image-cover"
                      class="d-none"
                      type="file"
                      v-model="imageCover"
                      accept="image/png, image/jpeg, image/bmp"
                      ref="fileImageCover"
                      @change="onChangeImageCover"
                    >
                    </base-input>
                    <div
                      class="card p-5 w-100 shadow my-3"
                      @click="chooseFilesImageCover"
                    >
                      <div class="text-center">
                        <i class="fa fa-image text-primary icon-image"></i>
                        <p class="heading mb-0">
                          Klik untuk menambahkan Gambar Profile
                        </p>
                        <p>JPEG atau PNG, tidak lebih besar dari 10MB.</p>
                      </div>
                    </div> -->

                    <h4 class="font-weight-bold">Tentang Organizer</h4>
                    <p>Beri tahu peserta siapa yang menyelenggarakan acara</p>

                    <label for="" class="mb-0">Nama Organizer</label>
                    <base-input
                      alternative
                      class="border-white mb-0"
                      inputClasses="p-0"
                      type="text"
                      style="box-shadow: none !important"
                      placeholder="Nama Organizer"
                      v-model="organizerName"
                      :required="true"
                    >
                    </base-input>
                    <hr class="m-0 mt-3" />

                    <h5 class="mt-3">Biodata Penyelenggara</h5>
                    <p>
                      Jelaskan siapa Anda, jenis acara yang Anda selenggarakan,
                      atau misi Anda. Bio ditampilkan di profil penyelenggara
                      Anda.
                    </p>
                    <vue-editor v-model="biodata" class="mt-3" />
                    <div class="col-md-12 mt-4 text-right">
                      <base-button type="secondary" @click="$router.go(-1)"
                        >Batal</base-button
                      >
                      <base-button type="primary" nativeType="submit">
                        Simpan Perubahan
                      </base-button>
                    </div>
                  </form>
                </tab-pane>

                <tab-pane title="Pembayaran">
                  <base-button
                    type="primary"
                    class="my-3"
                    @click="modals.modalCreate.status = true"
                    v-if="paymentList.length > 0"
                  >
                    Tambahkan Metode Pembayaran
                  </base-button>

                  <div v-if="paymentList.length == 0">
                    <div class="py-2">
                      <h3>Tidak ada metode pembayaran yang disiapkan</h3>
                      <p class="description">
                        Siapkan metode pembayaran agar peserta dapat membayaran
                        sesuai metode pembayaran yang telah ditetapkan.
                      </p>
                      <base-button
                        type="primary"
                        @click="modals.modalCreate.status = true"
                      >
                        Tambahkan Metode Pembayaran
                      </base-button>
                    </div>
                  </div>

                  <div v-if="paymentList.length > 0" style="overflow: auto">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col" class="col-md-2">
                            <small>Bank</small>
                          </th>
                          <th scope="col" class="col-md-2">
                            <small>Nama Rekening</small>
                          </th>
                          <th scope="col" class="col-md-2">
                            <small>No Rekening</small>
                          </th>
                          <th scope="col" class="col-md-2">
                            <small></small>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="list in paymentList" :key="list.id">
                          <td>
                            <span class="text-primary">{{ list.bank }}</span>
                          </td>
                          <td>
                            <span>{{ list.bank_name }}</span>
                          </td>
                          <td>
                            <span>{{ list.bank_number }}</span>
                          </td>
                          <td>
                            <div class="row">
                              <div class="text-right">
                                <i
                                  class="fa fa-pencil mr-3 cursor-pointer"
                                  @click="openModalEdit(list.id)"
                                ></i>
                                <i
                                  class="fa fa-trash text-danger cursor-pointer"
                                  @click="deletePayment(list.id)"
                                ></i>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </tab-pane>
              </card>
            </tabs>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import Tabs from "@/components/Tabs/Tabs.vue";
import TabPane from "@/components/Tabs/TabPane.vue";
import { VueEditor } from "vue2-editor";
import Modal from "@/components/Modal.vue";
import axios from "axios";

import debounce from "lodash.debounce";
import { sweatalert } from "@/reactivities/sweatalert.js";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  components: {
    "v-select": vSelect,
    Modal,
    Tabs,
    TabPane,
    VueEditor,
  },
  computed: {
    loggedIn() {
      return JSON.parse(localStorage.getItem("token"));
    },
  },
  created() {
    if (this.loggedIn) {
      this.isLogin = true;
      this.fullname = JSON.parse(localStorage.getItem("user")).name;
    }
    this.getData();
  },
  data() {
    return {
      modals: {
        modalCreate: {
          status: false,
          data: {
            name: "",
            bankNo: "",
            bankName: "",
          },
        },
        modalEdit: {
          status: false,
          data: {
            id: "",
            name: "",
            bankNo: "",
            bankName: "",
          },
        },
      },
      organizerId: "",
      fullname: "",
      urlImageProfile: "",
      imageProfile: "",
      imageCover: "",
      urlImageCover: "",
      organizerName: "",
      website: "",
      biodata: "",
      img: "https://images.pexels.com/photos/226746/pexels-photo-226746.jpeg",
      url: `${process.env.VUE_APP_API}/api/v1/dashboard/setting/organizer/edit`,
      urlCreatePayment: `${process.env.VUE_APP_API}/api/v1/dashboard/setting/payment/create`,
      urlUpdatePayment: `${process.env.VUE_APP_API}/api/v1/dashboard/setting/payment/update`,
      urlDeletePayment: `${process.env.VUE_APP_API}/api/v1/dashboard/setting/payment/delete`,
      urlBank: `${process.env.VUE_APP_API}/api/v1/bank`,
      authToken: "Bearer " + JSON.parse(localStorage.getItem("token")),
      options: [],
      paymentList: [],
    };
  },
  methods: {
    getData: function () {
      if (JSON.parse(localStorage.getItem("token"))) {
        axios
          .get(this.url, {
            headers: { Authorization: this.authToken },
          })
          .then(
            (res) => {
              if (res.data.data) {
                this.organizerId = res.data.data.id;
                this.organizerName = res.data.data.name;
                this.biodata = res.data.data.desc;
              }
            },
            (err) => {
              if (err.response.data.message) {
                sweatalert.showAlert("error", err.response.data.message);
              } else {
                sweatalert.showAlert("error", "Maaf telah terjadi kesalahan");
              }
            }
          );
      }

      axios.get(`${process.env.VUE_APP_API}/api/v1/bank`).then(
        (res) => {
          if (res.data.data.data) {
            this.options = res.data.data.data;
          }
        },
        (err) => {
          if (err.response.data.message) {
            sweatalert.showAlert("error", err.response.data.message);
          } else {
            sweatalert.showAlert("error", "Maaf telah terjadi kesalahan");
          }
        }
      );

      axios
        .get(`${process.env.VUE_APP_API}/api/v1/dashboard/setting/payment`, {
          headers: { Authorization: this.authToken },
        })
        .then(
          (res) => {
            if (res.data.data) {
              this.paymentList = res.data.data;
            }
          },
          (err) => {
            if (err.response.data.message) {
              sweatalert.showAlert("error", err.response.data.message);
            } else {
              sweatalert.showAlert("error", "Maaf telah terjadi kesalahan");
            }
          }
        );
    },
    onSearch(search, loading) {
      if (search.length) {
        loading(true);
        this.search(loading, search, this);
      }
    },
    search: debounce((loading, search, vm) => {
      fetch(`${process.env.VUE_APP_API}/api/v1/bank?q=${escape(search)}`).then(
        (res) => {
          res.json().then((json) => {
            vm.options = json.data.data;
          });
          loading(false);
        }
      );
    }, 350),
    chooseFilesImageProfile: function () {
      document.getElementById("image-profile").click();
    },
    chooseFilesImageCover: function () {
      document.getElementById("image-cover").click();
    },
    onChangeImageProfile: function (e) {
      const file = e.target.files[0];

      const image = URL.createObjectURL(file);
      this.urlImageProfile = image;
      this.imageProfile = file;
    },
    onChangeImageCover: function (e) {
      const file = e.target.files[0];

      const image = URL.createObjectURL(file);
      this.urlImageCover = image;
      this.imageCover = file;
    },
    saveOrganizer: function () {
      const data = {
        id: this.organizerId,
        name: this.organizerName,
        desc: this.biodata,
      };
      axios
        .post(this.url, data, {
          headers: { Authorization: this.authToken },
        })
        .then(
          (res) => {
            if (res.data.success) {
              this.organizerName = res.data.data.name;
              this.biodata = res.data.data.desc;

              sweatalert.showAlert("success", res.data.message);

              this.reloadPage();
            }
          },
          (err) => {
            if (err.response.data.message) {
              sweatalert.showAlert("error", err.response.data.message);
            } else {
              sweatalert.showAlert("error", "Maaf telah terjadi kesalahan");
            }
          }
        );
    },
    savePayment: function () {
      const data = {
        bank: this.modals.modalCreate.data.name,
        bank_name: this.modals.modalCreate.data.bankName,
        bank_number: this.modals.modalCreate.data.bankNo,
      };
      axios
        .post(this.urlCreatePayment, data, {
          headers: { Authorization: this.authToken },
        })
        .then(
          (res) => {
            if (res.data.success) {
              this.paymentList = res.data.data;
              sweatalert.showAlert("success", res.data.message);
              this.modals.modalCreate.status = false;

              this.reloadPage();
            }
          },
          (err) => {
            if (err.response.data.message) {
              sweatalert.showAlert("error", err.response.data.message);
            } else {
              sweatalert.showAlert("error", "Maaf telah terjadi kesalahan");
            }
          }
        );
    },
    updatePayment: function () {
      const data = {
        id: this.modals.modalEdit.data.id,
        bank: this.modals.modalEdit.data.name,
        bank_name: this.modals.modalEdit.data.bankName,
        bank_number: this.modals.modalEdit.data.bankNo,
      };
      axios
        .post(this.urlUpdatePayment, data, {
          headers: { Authorization: this.authToken },
        })
        .then(
          (res) => {
            if (res.data.success) {
              this.paymentList = res.data.data;
              sweatalert.showAlert("success", res.data.message);
              this.modals.modalEdit.status = false;

              this.reloadPage();
            }
          },
          (err) => {
            if (err.response.data.message) {
              sweatalert.showAlert("error", err.response.data.message);
            } else {
              sweatalert.showAlert("error", "Maaf telah terjadi kesalahan");
            }
          }
        );
    },
    onChange: function ({ coordinates, canvas }) {
      this.urlImageProfile = canvas.toDataURL();
      canvas.toBlob((blob) => {
        this.imageProfile = new File([blob], "image.png");
      });
    },
    openModalEdit: function (paymentId) {
      const payment = this.paymentList.find((x) => x.id === paymentId);

      this.modals.modalEdit.data.id = payment.id;
      this.modals.modalEdit.data.name = payment.bank;
      this.modals.modalEdit.data.bankName = payment.bank_name;
      this.modals.modalEdit.data.bankNo = payment.bank_number;

      this.modals.modalEdit.status = true;
    },
    deletePayment: function (paymentId) {
      let text = "Apakah anda ingin menghapus ini?";

      if (confirm(text) == true) {
        const data = {
          id: paymentId,
        };

        axios
          .post(this.urlDeletePayment, data, {
            headers: { Authorization: this.authToken },
          })
          .then(
            (res) => {
              if (res.data.success) {
                this.paymentList = res.data.data;
                sweatalert.showAlert("success", res.data.message);

                this.reloadPage();
              }
            },
            (err) => {
              if (err.response.data.message) {
                sweatalert.showAlert("error", err.response.data.message);
              } else {
                sweatalert.showAlert("error", "Maaf telah terjadi kesalahan");
              }
            }
          );
      }
    },
    reloadPage: debounce((e) => {
      // location.reload();
    }, 1000),
  },
};
</script>
<style>
.cropper {
  height: 300px;
  background: #ddd;
}
.card-image-profile {
  width: 300px;
  height: 300px;
}
</style>
