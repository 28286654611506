<template>
  <div>
    <section class="section section-shaped section-lg my-0">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="mb-4">
              <router-link :to="{ name: 'dashboardEvents', params: {} }">
                <small><i class="fa fa-angle-left"></i> Daftar Acara</small>
              </router-link>
            </div>
            <h3><b>Buat Acara Baru</b></h3>
            <p>
              Beri nama acara Anda dan beri tahu pengunjung acara mengapa mereka
              harus datang. Tambahkan detail yang menonjolkan apa yang
              membuatnya unik.
            </p>
          </div>
        </div>
        <form ref="form" action="">
          <div class="row">
            <div class="col-lg-9 col-md-12 mb-3">
              <label for="" class="mb-0">Nama Acara</label>
              <base-input
                alternative
                class="border-white mb-0"
                inputClasses="p-0"
                type="text"
                v-model="eventTitle"
                style="box-shadow: none !important"
                placeholder="Nama Acara"
                :required="true"
              >
              </base-input>
              <hr class="m-0 mt-3" />
            </div>
            <div class="col-lg-4 col-md-6 col-6 mb-3">
              <label for="" class="mb-0">Tipe Acara</label>
              <v-select
                label="typeevent"
                class="mt-2"
                :filterable="false"
                :options="optionsTypes"
                @search="typeEventOnSearch"
                placeholder="Pilih Tipe Acara"
                v-model="eventType"
                :reduce="(optionsTypes) => optionsTypes.id"
                :getOptionLabel="(optionsTypes) => optionsTypes.name"
                :clearable="false"
              >
              </v-select>
            </div>
            <div class="col-lg-4 col-md-6 col-6 mb-3">
              <label for="" class="mb-0">Kategori Acara</label>
              <v-select
                label="categoryEvent"
                class="mt-2"
                :filterable="false"
                :options="optionCategories"
                @search="catEventOnSearch"
                placeholder="Pilih Kategori Acara"
                v-model="eventCategory"
                :reduce="(optionCategories) => optionCategories.id"
                :getOptionLabel="(optionCategories) => optionCategories.name"
                :clearable="false"
              >
              </v-select>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-md-12">
              <h5><b>Tags</b></h5>
              <p>
                Tingkatkan kemampuan untuk menemukan acara Anda dengan
                menambahkan tag yang relevan dengan acara.
              </p>
            </div>
            <div class="col-lg-8 col-md-12">
              <label for="" class="mb-0">Masukan Tag</label>
              <v-select
                label="tagEvent"
                class="mt-2"
                :filterable="true"
                @search="tagEventOnSearch"
                placeholder="Pilih Tag Acara"
                v-model="eventTags"
                :options="optionsTags"
                :reduce="(optionsTags) => optionsTags.name"
                :getOptionLabel="(optionsTags) => optionsTags.name"
                :taggable="true"
                :multiple="true"
              >
              </v-select>
              <hr class="m-0 mt-3" />
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-md-12">
              <h5><b>Tempat Acara Diadakan</b></h5>
              <p>
                Bantu orang-orang di area tersebut menemukan acara Anda dan beri
                tahu peserta di mana harus hadir.
              </p>
            </div>
            <div class="col-md-12 col-lg-8">
              <tabs fill class="flex-column flex-md-row" v-if="!locationReady">
                <card shadow>
                  <tab-pane title="Offline Event">
                    <div class="p-2">
                      <label
                        class="float-right cursor-pointer"
                        @click="locationReady = true"
                        >Batal</label
                      >
                      <label for="">Masukan Tempat Acara</label>

                      <!-- <vue-google-autocomplete
                        id="map"
                        classname="form-control border-white px-0 mb-0"
                        placeholder="Masukan alamat tempat"
                        v-on:placechanged="getLocationData"
                        :country="id"
                      >
                      </vue-google-autocomplete> -->

                      <GMapAutocomplete
                        class="form-control"
                        placeholder="Masukan alamat"
                        @place_changed="getLocationData"
                        :options="{
                          fields: ['formatted_address', 'geometry', 'name'],
                          strictBounds: true,
                        }"
                      >
                      </GMapAutocomplete>
                    </div>
                  </tab-pane>

                  <tab-pane title="Online Event">
                    <div class="p-2">
                      <p class="description">
                        Acara online memiliki ruang acara unik tempat Anda dapat
                        menambahkan tautan ke streaming langsung dan banyak
                        lagi.
                      </p>
                    </div>
                  </tab-pane>
                </card>
              </tabs>

              <card class="p-0" shadow v-if="locationReady">
                <div class="py-2">
                  <GMapMap
                    :center="center"
                    :zoom="14"
                    map-type-id="terrain"
                    style="width: 100%; height: 300px"
                    :options="{
                      draggable: false,
                      zoomControl: false,
                      mapTypeControl: false,
                      scaleControl: false,
                      streetViewControl: false,
                      rotateControl: false,
                      fullscreenControl: false,
                    }"
                  >
                    <GMapCluster>
                      <GMapMarker
                        v-if="center.lat"
                        :position="{
                          lat: center.lat,
                          lng: center.lng,
                        }"
                        :clickable="true"
                        :draggable="false"
                      />
                    </GMapCluster>
                  </GMapMap>

                  <div class="row mt-3">
                    <div class="col-md-10 col-10">
                      <p class="mb-0">{{ eventLocation }}</p>
                    </div>
                    <div class="col-md-2 col-2 text-center">
                      <i
                        class="fa fa-search cursor-pointer"
                        @click="locationReady = false"
                      ></i>
                    </div>
                  </div>
                </div>
              </card>
            </div>
            <div class="col-md-12 col-lg-8">
              <hr class="m-0 mt-4" />
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-md-12">
              <h5><b>Tanggal dan waktu</b></h5>
              <p>
                Beri tahu pengunjung acara kapan acara Anda dimulai dan berakhir
                sehingga mereka dapat membuat rencana untuk hadir.
              </p>
            </div>
            <div class="col-md-6 col-lg-4">
              <label for="" class="mb-2">Waktu Acara Dimulai</label>
              <base-input addon-left-icon="ni ni-calendar-grid-58">
                <flat-picker
                  :v-slot="{ focus, blur }"
                  @on-open="focus"
                  @on-close="blur"
                  :config="configDatePickerEventStart"
                  class="form-control datepicker"
                  @on-change="onChangeCreateFrom"
                  v-model="eventStartDate"
                >
                </flat-picker>
              </base-input>
            </div>
            <div class="col-md-6 col-lg-4">
              <label for="" class="mb-2">Waktu Acara Selesai</label>
              <base-input addon-left-icon="ni ni-calendar-grid-58">
                <flat-picker
                  :v-slot="{ focus, blur }"
                  @on-open="focus"
                  @on-close="blur"
                  :config="configDatePickerEventEnd"
                  class="form-control datepicker"
                  @on-change="onChangeCreateTo"
                  v-model="eventEndDate"
                >
                </flat-picker>
              </base-input>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-md-10">
              <router-link
                class="text-light"
                :to="{ name: 'dashboardEvents', params: {} }"
              >
                <base-button type="secondary" class="mr-4">Batal</base-button>
              </router-link>
              <base-button
                type="primary"
                class="mr-0"
                nativeType="submit"
                @click="submitForm()"
                v-if="!isEdit"
              >
                Simpan Perubahan
              </base-button>
              <base-button
                type="primary"
                class="mr-0"
                nativeType="submit"
                @click="submitUpdate()"
                v-if="isEdit"
              >
                Simpan Perubahan
              </base-button>
            </div>
          </div>
        </form>
      </div>
    </section>
  </div>
</template>
<script>
import Tabs from "@/components/Tabs/Tabs.vue";
import TabPane from "@/components/Tabs/TabPane.vue";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import debounce from "lodash.debounce";
import axios from "axios";
import { storeProgressCreateEvent } from "@/reactivities/storeProgressCreateEvent.js";
import { sweatalert } from "@/reactivities/sweatalert.js";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import VueGoogleAutocomplete from "vue-google-autocomplete";

export default {
  components: {
    "v-select": vSelect,
    Tabs,
    TabPane,
    flatPicker,
  },
  mounted() {
    storeProgressCreateEvent.isEdit = true;

    if (this.eventId) {
      this.getEvent();
    }
  },
  created() {
    this.getData();
  },
  data() {
    return {
      center: { lat: 0, lng: 0 },
      storeProgressCreateEvent,
      isEdit: false,
      eventId: this.$route.params.e,
      eventTitle: "",
      eventCategory: "",
      eventType: "",
      eventLocation: "",
      latitude: "",
      longitude: "",
      eventTags: [],
      eventStartDate: "",
      eventEndDate: "",
      optionsTypes: [],
      optionCategories: [],
      optionsTags: [],
      locationReady: false,
      configDatePickerEventStart: {
        enableTime: true,
        dateFormat: "Y-m-d H:i",
        allowInput: true,
      },
      configDatePickerEventEnd: {
        enableTime: true,
        dateFormat: "Y-m-d H:i",
        allowInput: true,
      },
      urlCreateEvent: `${process.env.VUE_APP_API}/api/v1/dashboard/e/basicinfo`,
      urlEditEvent: `${process.env.VUE_APP_API}/api/v1/dashboard/e/basicinfo`,
      authToken: "Bearer " + JSON.parse(localStorage.getItem("token")),
    };
  },
  methods: {
    getData: function () {
      axios.get(`${process.env.VUE_APP_API}/api/v1/types`).then(
        (res) => {
          if (res.data.data) {
            this.optionsTypes = res.data.data;
          }
        },
        (err) => {
          if (err.response.data.message) {
            sweatalert.showAlert("error", err.response.data.message);
          } else {
            sweatalert.showAlert("error", "Maaf telah terjadi kesalahan");
          }
        }
      );

      axios.get(`${process.env.VUE_APP_API}/api/v1/categories`).then(
        (res) => {
          if (res.data.data) {
            this.optionCategories = res.data.data;
          }
        },
        (err) => {
          if (err.response.data.message) {
            sweatalert.showAlert("error", err.response.data.message);
          } else {
            sweatalert.showAlert("error", "Maaf telah terjadi kesalahan");
          }
        }
      );

      axios.get(`${process.env.VUE_APP_API}/api/v1/tags`).then(
        (res) => {
          if (res.data.data) {
            this.optionsTags = res.data.data;
          }
        },
        (err) => {
          if (err.response.data.message) {
            sweatalert.showAlert("error", err.response.data.message);
          } else {
            sweatalert.showAlert("error", "Maaf telah terjadi kesalahan");
          }
        }
      );
    },
    getEvent: function () {
      axios
        .get(`${this.urlEditEvent}?id=${this.eventId}`, {
          headers: { Authorization: this.authToken },
        })
        .then(
          (res) => {
            if (res.data.data) {
              this.eventTitle = res.data.data.title;
              this.eventCategory = res.data.data.category_id;
              this.eventType = res.data.data.type_id;
              this.eventLocation = res.data.data.location;
              this.eventStartDate = res.data.data.from;
              this.eventEndDate = res.data.data.to;
              this.center.lat = parseFloat(res.data.data.latitude);
              this.center.lng = parseFloat(res.data.data.longitude);
              this.latitude = parseFloat(res.data.data.latitude);
              this.longitude = parseFloat(res.data.data.longitude);

              if (this.center.lat) {
                this.locationReady = true;
              }

              let newListTags = res.data.data.tags.map(function (obj) {
                return obj.name;
              });

              this.eventTags = newListTags;

              this.isEdit = true;
            }
          },
          (err) => {
            if (err.response.data.message) {
              sweatalert.showAlert("error", err.response.data.message);
            } else {
              sweatalert.showAlert("error", "Maaf telah terjadi kesalahan");
            }
          }
        );
    },
    onChangeCreateFrom: function (selectedDates, dateStr, instance) {
      this.eventStartDate = dateStr;
    },
    onChangeCreateTo: function (selectedDates, dateStr, instance) {
      this.eventEndDate = dateStr;
    },
    submitForm: function () {
      this.$refs.form.addEventListener("submit", (event) => {
        event.preventDefault();

        const data = {
          category_id: this.eventCategory,
          type_id: this.eventType,
          tags: this.eventTags,
          title: this.eventTitle,
          from: this.eventStartDate,
          to: this.eventEndDate,
          location: this.eventLocation,
          latitude: this.latitude,
          longitude: this.longitude,
        };

        axios
          .post(this.urlCreateEvent, data, {
            headers: { Authorization: this.authToken },
          })
          .then(
            (res) => {
              if (res.data.success) {
                sweatalert.showAlert("success", res.data.message);

                this.$router.push({
                  name: "createDetail",
                  params: { e: res.data.data },
                });
              }
            },
            (err) => {
              if (err.response.data.message) {
                sweatalert.showAlert("error", err.response.data.message);
              } else {
                sweatalert.showAlert("error", "Maaf telah terjadi kesalahan");
              }
            }
          );
      });
    },
    submitUpdate: function () {
      this.$refs.form.addEventListener("submit", (event) => {
        const data = {
          category_id: this.eventCategory,
          type_id: this.eventType,
          tags: this.eventTags,
          title: this.eventTitle,
          from: this.eventStartDate,
          to: this.eventEndDate,
          location: this.eventLocation,
          latitude: this.latitude,
          longitude: this.longitude,
        };

        axios
          .post(`${this.urlEditEvent}/${this.eventId}`, data, {
            headers: { Authorization: this.authToken },
          })
          .then(
            (res) => {
              if (res.data.success) {
                sweatalert.showAlert("success", res.data.message);

                this.$router.push({
                  name: "createDetail",
                  params: { e: res.data.data },
                });
              }
            },
            (err) => {
              if (err.response.data.message) {
                sweatalert.showAlert("error", err.response.data.message);
              } else {
                sweatalert.showAlert("error", "Maaf telah terjadi kesalahan");
              }
            }
          );
      });
    },
    typeEventOnSearch(search, loading) {
      if (search.length) {
        loading(true);
        this.typeEvent(loading, search, this);
      }
    },
    typeEvent: debounce((loading, search, vm) => {
      fetch(`${process.env.VUE_APP_API}/api/v1/types?q=${escape(search)}`).then(
        (res) => {
          res.json().then((json) => {
            vm.optionsTypes = json.data;
          });
          loading(false);
        }
      );
    }, 350),
    catEventOnSearch(search, loading) {
      if (search.length) {
        loading(true);
        this.catEvent(loading, search, this);
      }
    },
    catEvent: debounce((loading, search, vm) => {
      fetch(
        `${process.env.VUE_APP_API}/api/v1/categories?q=${escape(search)}`
      ).then((res) => {
        res.json().then((json) => {
          vm.optionCategories = json.data;
        });
        loading(false);
      });
    }, 350),
    tagEventOnSearch(search, loading) {
      if (search.length) {
        loading(true);
        this.tagEvent(loading, search, this);
      }
    },
    tagEvent: debounce((loading, search, vm) => {
      fetch(`${process.env.VUE_APP_API}/api/v1/tags?q=${escape(search)}`).then(
        (res) => {
          res.json().then((json) => {
            vm.optionsTags = json.data;
          });
          loading(false);
        }
      );
    }, 350),
    getLocationData: function (addressData) {
      let coordinate = addressData.geometry.location.toString().split(" ");

      this.eventLocation = addressData.formatted_address;
      this.latitude = coordinate[0].replace("(", "");
      this.longitude = coordinate[1].replace(")", "");
    },
  },
};
</script>
<style>
.vs__selected-options {
  padding: 10px;
}
.select-container {
  overflow: auto;
}
</style>
