<template>
  <div>
    <section class="section section-shaped section-lg my-0">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="mb-4">
              <h3>Transaksi Pembelian</h3>
            </div>
            <div class="mt-3" style="overflow: auto">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col" class="col-md-1">
                      <small>Transaksi</small>
                    </th>
                    <th scope="col" class="col-md-2"><small>Acara</small></th>
                    <th scope="col" class="col-md-2"><small>Pembeli</small></th>
                    <th scope="col" class="col-md-2"><small>Tanggal</small></th>
                    <th scope="col" class="col-md-2"><small>Status</small></th>
                    <th scope="col" class="col-md-2"><small>Total</small></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="list in listOrder" :key="list.order_id">
                    <td>
                      <p>#{{ list.order_id }}</p>
                    </td>
                    <td>
                      <span class="text-primary">{{
                        list.order_event_name
                      }}</span>
                    </td>
                    <td>
                      <span>{{ list.order_participant_name }}</span>
                    </td>
                    <td>
                      <span>{{ list.order_date }}</span>
                    </td>
                    <td>
                      <span style="text-transform: capitalize">{{
                        list.order_status
                      }}</span>
                    </td>
                    <td>
                      <div class="row">
                        <div class="col-md-8 col-12">
                          <span>{{ list.order_amount }}</span>
                        </div>
                        <div class="col-md-4 col-12 text-right">
                          <i
                            class="fa fa-eye cursor-pointer mr-2 text-primary"
                            @click="showOrder(list.order_id)"
                          ></i>
                          <i
                            v-if="list.order_status == 'completed'"
                            class="fa fa-print cursor-pointer text-warning"
                            @click="printTicket(list.order_id)"
                          ></i>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="listOrder.length == 0">
                    <td colspan="3">
                      <p>Belum ada data transaksi</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import axios from "axios";
import { sweatalert } from "@/reactivities/sweatalert.js";

export default {
  components: {},
  created() {
    this.getData();
  },
  data() {
    return {
      eventId: this.$route.params.e,
      authToken: "Bearer " + JSON.parse(localStorage.getItem("token")),
      urlGetOrder: `${process.env.VUE_APP_API}/api/v1/dashboard/transaction/event/${this.$route.params.e}`,
      listOrder: "",
      urlPrintTicket: `${process.env.VUE_APP_API}/api/v1/print/ticket/`,
    };
  },
  methods: {
    getData: function () {
      axios
        .get(this.urlGetOrder, {
          headers: {
            Authorization: this.authToken,
          },
        })
        .then(
          (res) => {
            if (res.data.data) {
              this.listOrder = res.data.data;
            }
          },
          (err) => {
            if (err.response.data.message) {
              sweatalert.showAlert("error", err.response.data.message);
            } else {
              sweatalert.showAlert("error", "Maaf telah terjadi kesalahan");
            }
          }
        );
    },
    showOrder: function (orderId) {
      this.$router.push({ name: "detailOrder", params: { orderId: orderId } });
    },
    printTicket: function (orderId) {
      const participantName = `${this.listOrder.find((element) => element.order_id == orderId).order_participant_name.toLowerCase().replace(" ", "-")}-` ?? '';
      const eventName = this.listOrder.find((element) => element.order_id == orderId).order_event_name.toLowerCase().replace(" ", "-");
      const filename = `tiket-${participantName}${eventName}-${Date.now()}.pdf`;

      axios
        .get(`${this.urlPrintTicket}${orderId}`, {
          headers: {
            Authorization: this.authToken,
            Accept: "application/json",
          },
          responseType: "blob",
        })
        .then(
          (response) => {
            if (response.data) {
              // var blobObj = new Blob([atob(response.data)], { type: "application/pdf" });

              // create file link in browser's memory
              const href = window.URL.createObjectURL(
                new Blob([response.data], { type: "application/pdf" })
              );

              // create "a" HTML element with href to file & click
              const link = document.createElement("a");
              link.href = href;
              link.setAttribute("download", filename); //or any other extension
              document.body.appendChild(link);
              link.click();
              // clean up "a" element & remove ObjectURL
              document.body.removeChild(link);
              URL.revokeObjectURL(href);
            }
          },
          (err) => {
            // sweatalert.showAlert("error", "Maaf telah terjadi kesalahan");
          }
        );
    },
  },
};
</script>
<style>
.cursor-pointer {
  cursor: pointer;
}
</style>
