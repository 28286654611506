<template>
  <div>
    <div class="container" style="margin-top: 130px">
      <div class="row">
        <div class="col-md-12 mt-3 mb-3">
          <div class="card" v-if="!isAlreadyAttendance">
            <div class="card-body mb-3 p-3">
              <h3 class="card-title mt-3">Berhasil Melakukan Presensi</h3>
              <b
                ><span class="mb-0" style="font-size: 50px; color: #333"
                  >Halo, {{ currentParticipant.nama }}</span
                ></b
              >
              <h5 class="card-text">
                Selamat datang. Anda dinyatakan
                <span class="text-success">Hadir</span>, pada <br> <br>
                <span class="text-primary">{{ currentParticipant.waktu }}</span>
              </h5>
            </div>
          </div>

          <div class="card" v-else>
            <div class="card-body mb-3">
              <h5 class="card-title mt-3 text-warning">
                Peserta Sudah Melakukan Presensi
              </h5>
              <!-- <p class="card-text">
                <b>Halo, {{ currentParticipant.nama }}</b> <br />
                Selamat datang. Anda dinyatakan
                <span class="text-success">Hadir</span>, pada
                {{ currentParticipant.waktu }}
              </p> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { sweatalert } from "@/reactivities/sweatalert.js";

export default {
  data() {
    return {
      bc: "",
      currentParticipant: {
        nama: "...",
        waktu: "...",
      },
      isAlreadyAttendance: false,
    };
  },
  components: {},
  created() {
    // Connection to a broadcast channel
    this.bc = new BroadcastChannel("mykiara_event");
    this.getBroadcastMessage();
  },
  mounted() {},
  methods: {
    getBroadcastMessage() {
      // listen 👂  for message
      this.bc.onmessage = (event) => {
        let data = event.data;

        this.currentParticipant.nama = data.data.nama_lengkap_primary;
        this.currentParticipant.waktu = data.data.waktu;
        this.isAlreadyAttendance = data.isAlreadyAttendance;
      };
    },
  },
};
</script>
<style>
</style>
