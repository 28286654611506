<template>
  <smart-tagz
    autosuggest
    :sources="optionsTags"
    :default-tags="optionsTags"
    input-placeholder="Masukan tagar ..."
    :theme="{
      primary: '#5e72e4',
      background: '#fff',
      tagTextColor: '#fff',
    }"
    :max-tags="10"
    @input="getData()"
  />
</template>

<script>
import { SmartTagz } from "smart-tagz";
import "smart-tagz/dist/smart-tagz.css";
import debounce from "lodash.debounce";
import axios from "axios";

import { defineComponent } from "vue";

export default defineComponent({
  name: "Basic",
  components: {
    SmartTagz,
  },
  data() {
    return {
      choiced: [],
      optionsTags: [],
    };
  },
  created() {
    // console.log(this.optionsTags, "ini sebelum");
    axios.get(`${process.env.VUE_APP_API}/api/v1/tags`).then(
      (res) => {
        if (res.data.data) {
          this.optionsTags = res.data.data.map((tag) => {
            return tag.name;
          });
        }

        // console.log(this.optionsTags);
      },
      (err) => {
        if (err.response.data.message) {
          sweatalert.showAlert("error", err.response.data.message);
        } else {
          sweatalert.showAlert("error", "Maaf telah terjadi kesalahan");
        }
      }
    );
    // console.log(this.optionsTags, "ini sesudah");
  },
  mounted() {
    // console.log("mounted");
  },
  methods: {
    getData: function () {
      axios.get(`${process.env.VUE_APP_API}/api/v1/tags`).then(
        (res) => {
          if (res.data.data) {
            this.optionsTags = res.data.data.map((tag) => {
              return tag.name;
            });
          }

          // console.log(this.optionsTags);
        },
        (err) => {
          if (err.response.data.message) {
            sweatalert.showAlert("error", err.response.data.message);
          } else {
            sweatalert.showAlert("error", "Maaf telah terjadi kesalahan");
          }
        }
      );
    },
    tagEventOnSearch(search, loading) {
      if (search.length) {
        loading(true);
        this.tagEvent(loading, search, this);
      }
    },
    tagEvent: debounce((loading, search, vm) => {
      fetch(`${process.env.VUE_APP_API}/api/v1/tags?q=${escape(search)}`).then(
        (res) => {
          res.json().then((json) => {
            vm.optionsTags = json.data.map((tag) => {
              return tag.name;
            });
          });
          loading(false);
        }
      );
    }, 350),
  },
});
</script>