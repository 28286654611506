<template>
  <section class="section section-shaped section-lg my-0">
    <div class="shape shape-style-1 bg-gradient-default">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
    <div class="container pt-lg-md">
      <div class="row justify-content-center">
        <div class="col-lg-5">
          <card
            type="secondary"
            shadow
            header-classes="bg-white pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0"
          >
            <div>
              <div class="text-muted text-center mb-3">
                <h1 class="heading">Forgot Password</h1>
              </div>
              <form role="form" @submit.prevent="forgotPassword">
                <base-input
                  alternative
                  class="mb-3"
                  placeholder="Email"
                  type="email"
                  v-model="email"
                  :required="true"
                  addon-left-icon="ni ni-email-83"
                >
                </base-input>
                Remember password?
                <router-link to="/login"> Masuk </router-link>
                <div class="text-center">
                  <base-button nativeType="primary" type="primary" class="my-4"
                    >Kirim Kode</base-button
                  >
                </div>
              </form>
            </div>
          </card>
          <div class="row mt-3">
            <!-- <div class="col-6">
              <a href="#" class="text-light">
                <small>Forgot password?</small>
              </a>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from "axios";
import { storeEmailForgotPassword } from "@/reactivities/storeEmailForgotPassword.js";
import { sweatalert } from "@/reactivities/sweatalert.js";

export default {
  data: () => ({
    email: "",
    remeberme: true,
    submitted: false,
    storeEmailForgotPassword,
    urlPost: `${process.env.VUE_APP_API}/api/v1/forgot-password`,
  }),
  mounted() {
    if (this.loggedIn) {
      this.$router.push({ name: "landing" });
    }

    storeEmailForgotPassword.rememberpasswordEmail = "";
  },
  methods: {
    forgotPassword: function () {
      axios
        .post(this.urlPost, {
          email: this.email,
        })
        .then(
          (res) => {
            if (res) {
              sweatalert.showAlert("success", res.data.message);

              storeEmailForgotPassword.rememberpasswordEmail = res.data.data.email

              setTimeout(
                () => this.$router.push({ name: "confirmationCode" }),
                2000
              );
            } else {
              sweatalert.showAlert(
                "error",
                "Maaf ada kesalahan. Mohon muat ulang."
              );
            }
          },
          (err) => {
            if (err.response.data.message) {
              sweatalert.showAlert("error", err.response.data.message);
            } else {
              sweatalert.showAlert("error", "Maaf telah terjadi kesalahan");
            }
          }
        );
    },
  },
};
</script>
<style>
.base-notification {
  position: absolute;
  top: 50px;
  right: 10px;
  min-width: 450px;
  z-index: 9999;
}
</style>