<template>
  <div>
    <section class="section section-shaped section-lg my-0">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="mb-4">
              <h3>Daftar Tiket</h3>
              <p>Daftar tiket yang sudah terbeli.</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div v-if="listTickets.length == 0">
                <p class="mt-3">Tiket anda kosong</p>
              </div>
              <div
                class="row mb-5"
                v-for="item in listTickets"
                :key="item.order_id"
              >
                <div class="col-md-5">
                  <div
                    class="bg-white text-center p-2"
                    style="position: absolute; width: 80px"
                  >
                    <span class="heading text-primary">{{ item.month }}</span>
                    <br />
                    <h5 class="">{{ item.day }}</h5>
                  </div>
                  <img
                    :src="item.order_event.image_url"
                    class="img-center img-fluid"
                    style=""
                  />
                </div>
                <div class="col-md-6 p-4">
                  <p class="heading font-weight-bold mb-2">
                    {{ item.order_event.title }}
                  </p>
                  <small>
                    {{ item.order_event.formatted_from }} <br />
                    {{ item.order_event.location }}
                  </small>
                  <div class="row">
                    <div
                      class="col-md-12"
                      style="position: absolute; bottom: 0"
                    >
                      <router-link
                        class="text-light"
                        :to="{
                          name: 'detailTicket',
                          params: { orderId: item.order_id },
                        }"
                      >
                        <base-button type="primary" class="w-100"
                          >Lihat Detail Tiket</base-button
                        >
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import axios from "axios";
import { sweatalert } from "@/reactivities/sweatalert.js";

export default {
  components: {},
  mounted() {
    this.getData();
  },
  data() {
    return {
      listTickets: "",
      urlGetMyTicket: `${process.env.VUE_APP_API}/api/v1/my/ticket`,
      authToken: "Bearer " + JSON.parse(localStorage.getItem("token")),
    };
  },
  methods: {
    getData: function () {
      axios
        .get(this.urlGetMyTicket, {
          headers: {
            Authorization: this.authToken,
          },
        })
        .then(
          (res) => {
            if (res.data.success) {
              this.listTickets = res.data.data;
            }
          },
          (err) => {
            if (err.response.data.message) {
              sweatalert.showAlert("error", err.response.data.message);
            }
            sweatalert.showAlert("error", "Maaf telah terjadi kesalahan");
          }
        );
    },
  },
};
</script>
<style>
</style>
