<template>
  <div>
    <section class="section section-shaped section-lg my-0">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="mb-4">
              <base-button
                class="btn-1 float-right"
                type="primary"
                @click="modals.modalCreate.status = true"
                >Buat Form Baru</base-button
              >

              <modal
                :show="modals.modalCreate.status"
                body-classes="p-0"
                modal-classes="modal-dialog-centered"
              >
                <div class="row">
                  <!-- Ticket Section -->
                  <div class="col-md-12" style="padding-right: 0px !important">
                    <card
                      type="secondary"
                      shadow
                      header-classes="bg-white"
                      body-classes="px-lg-1 py-lg-3 bg-white"
                      footer-classes="bg-white"
                      class="border-0"
                    >
                      <div class="container">
                        <form role="form" @submit.prevent="saveQuestion">
                          <div class="row text-left border-bottom mb-3">
                            <div class="col-md-12">
                              <h5 class="mb-3">Tambahkan Pertanyaan Baru</h5>
                              <hr class="mb-3 mt-3" />

                              <label for="" class="mb-0">Pertanyaan</label>
                              <base-input
                                alternative
                                class="mb-3"
                                type="text"
                                placeholder="Pertanyaan"
                                :required="true"
                                v-model="modals.modalCreate.data.question"
                              >
                              </base-input>
                            </div>
                            <div class="col-md-12">
                              <label for="" class="mb-0"
                                >Format pertanyaan</label
                              >
                              <v-select
                                label="questionFormat"
                                class="mt-2"
                                :filterable="false"
                                :options="questionTypes"
                                placeholder="Pilih Format pertanyaan"
                                v-model="modals.modalCreate.data.questionFormat"
                                :reduce="(optionsTypes) => optionsTypes.id"
                                :getOptionLabel="
                                  (optionsTypes) => optionsTypes.label
                                "
                                :clearable="false"
                              >
                              </v-select>
                            </div>

                            <div
                              class="col-md-12"
                              v-if="
                                modals.modalCreate.data.questionFormat ==
                                  'multiple-choice' ||
                                modals.modalCreate.data.questionFormat ==
                                  'single-choice'
                              "
                            >
                              <div class="row">
                                <div class="col-md-12 mt-3">
                                  <label for="" class="mb-0"
                                    ><b>Pilihan yang tersedia</b></label
                                  >
                                </div>

                                <div
                                  v-for="(item, index) of modals.modalCreate
                                    .data.options"
                                  :key="item.index"
                                  class="col-md-12"
                                >
                                  <div class="row">
                                    <div class="col-md-10 mt-1">
                                      <base-input
                                        alternative
                                        class="mb-1"
                                        inputClasses="inputOption"
                                        type="text"
                                        placeholder="Pilihan"
                                        :required="true"
                                        v-model="
                                          modals.modalCreate.data.options[index]
                                            .label
                                        "
                                      >
                                      </base-input>
                                    </div>
                                    <div class="col-md-2 text-center p-2">
                                      <h3 @click="deleteOption(item.id)">
                                        <i
                                          class="
                                            fa fa-trash
                                            text-danger
                                            pt-3
                                            cursor-pointer
                                            del-option
                                          "
                                          :class="`del-option-${item.id}`"
                                        ></i>
                                      </h3>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="row">
                                <div class="col-md-5">
                                  <hr />
                                </div>
                                <div class="col-md-2 py-3 text-center">
                                  <h3 @click="addNewOption">
                                    <i
                                      class="
                                        fa fa-plus-circle
                                        cursor-pointer
                                        text-success
                                      "
                                    ></i>
                                  </h3>
                                </div>
                                <div class="col-md-5">
                                  <hr />
                                </div>
                              </div>
                            </div>

                            <div class="col-md-6 col-12 my-3">
                              <base-button
                                type="default"
                                class="mr-3 w-100"
                                @click="modals.modalCreate.status = false"
                                >Batal</base-button
                              >
                            </div>
                            <div class="col-md-6 col-12 my-3">
                              <base-button
                                type="primary"
                                class="mr-3 w-100"
                                nativeType="submit"
                                >Simpan Pertanyaan</base-button
                              >
                            </div>
                          </div>
                        </form>
                      </div>
                    </card>
                  </div>
                </div>
              </modal>

              <modal
                :show="modals.modalEdit.status"
                body-classes="p-0"
                modal-classes="modal-dialog-centered"
              >
                <div class="row">
                  <!-- Ticket Section -->
                  <div class="col-md-12" style="padding-right: 0px !important">
                    <card
                      type="secondary"
                      shadow
                      header-classes="bg-white"
                      body-classes="px-lg-1 py-lg-3 bg-white"
                      footer-classes="bg-white"
                      class="border-0"
                    >
                      <div>
                        <div class="container">
                          <form role="form" @submit.prevent="updateQuestion">
                            <div class="row text-left border-bottom mb-3">
                              <div class="col-md-12">
                                <h5 class="mb-3">Tambahkan Pertanyaan Baru</h5>
                                <hr class="mb-3 mt-3" />

                                <label for="" class="mb-0">Pertanyaan</label>
                                <base-input
                                  alternative
                                  class="mb-3"
                                  type="text"
                                  placeholder="Pertanyaan"
                                  :required="true"
                                  v-model="modals.modalEdit.data.question"
                                >
                                </base-input>
                              </div>
                              <div class="col-md-12">
                                <label for="" class="mb-0"
                                  >Format pertanyaan</label
                                >
                                <v-select
                                  label="questionFormat"
                                  class="mt-2"
                                  :filterable="false"
                                  :options="questionTypes"
                                  placeholder="Pilih Format pertanyaan"
                                  v-model="modals.modalEdit.data.questionFormat"
                                  :reduce="(optionsTypes) => optionsTypes.id"
                                  :getOptionLabel="
                                    (optionsTypes) => optionsTypes.label
                                  "
                                  :clearable="false"
                                >
                                </v-select>
                              </div>

                              <div
                                class="col-md-12"
                                v-if="
                                  modals.modalEdit.data.questionFormat ==
                                    'multiple-choice' ||
                                  modals.modalEdit.data.questionFormat ==
                                    'single-choice'
                                "
                              >
                                <div class="row">
                                  <div class="col-md-12 mt-3">
                                    <label for="" class="mb-0"
                                      ><b>Pilihan yang tersedia</b></label
                                    >
                                  </div>

                                  <div
                                    v-for="(item, index) of modals.modalEdit
                                      .data.options"
                                    :key="item.index"
                                    class="col-md-12"
                                  >
                                    <div class="row">
                                      <div class="col-md-10 mt-1">
                                        <base-input
                                          alternative
                                          class="mb-1"
                                          inputClasses="inputOption"
                                          type="text"
                                          placeholder="Pilihan"
                                          :required="true"
                                          v-model="
                                            modals.modalEdit.data.options[index]
                                              .label
                                          "
                                        >
                                        </base-input>
                                      </div>
                                      <div class="col-md-2 text-center p-2">
                                        <h3 @click="deleteOptionEdit(item.id)">
                                          <i
                                            class="
                                              fa fa-trash
                                              text-danger
                                              pt-3
                                              cursor-pointer
                                              del-option-edit
                                            "
                                          ></i>
                                        </h3>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="row">
                                  <div class="col-md-5">
                                    <hr />
                                  </div>
                                  <div class="col-md-2 py-3 text-center">
                                    <h3 @click="addNewOptionEdit">
                                      <i
                                        class="
                                          fa fa-plus-circle
                                          cursor-pointer
                                          text-success
                                        "
                                      ></i>
                                    </h3>
                                  </div>
                                  <div class="col-md-5">
                                    <hr />
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-6 col-12 my-3">
                                <base-button
                                  type="default"
                                  class="mr-3 w-100"
                                  @click="modals.modalEdit.status = false"
                                  >Batal</base-button
                                >
                              </div>
                              <div class="col-md-6 col-12 my-3">
                                <base-button
                                  type="primary"
                                  class="mr-3 w-100"
                                  nativeType="submit"
                                  >Simpan Pertanyaan</base-button
                                >
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </card>
                  </div>
                </div>
              </modal>

              <h3>Kustom Form</h3>
              <p>
                Mengelola informasi yang anda kumpulkan dari peserta selama
                checkout.
              </p>
            </div>
            <div class="mt-3">
              <table class="table table-ticket">
                <thead>
                  <tr>
                    <th scope="col" class="col-md-6 col-7">Detail</th>
                    <th scope="col" class="col-md-3 col-2">Wajib</th>
                    <th scope="col" class="col-md-3 col-3"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="list in listForms" :key="list.id">
                    <td>
                      <div class="row">
                        <div class="col-md-12">
                          {{ list.name }}
                          <div class="description">
                            <p>
                              {{ list.label }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="row">
                        <div class="col-md-8">
                          <dd class="font-weight-bold">
                            <div @click="requireChange(list.id)">
                              <base-switch
                                v-model="list.required"
                              ></base-switch>
                            </div>
                          </dd>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="row">
                        <div class="col-md-12 text-left">
                          <i
                            class="fa fa-pencil cursor-pointer mr-4"
                            @click="editForm(list.id)"
                          ></i>
                          <i
                            class="fa fa-trash text-danger cursor-pointer"
                            @click="deleteTicket(list.id)"
                          ></i>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="listForms.length == 0">
                    <p class="py-2">Kustom form belum anda buat</p>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import Modal from "@/components/Modal.vue";
import axios from "axios";
import { sweatalert } from "@/reactivities/sweatalert.js";
import debounce from "lodash.debounce";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  components: {
    "v-select": vSelect,
    Modal
  },
  created() {
    this.getData();
  },
  data() {
    return {
      eventId: this.$route.params.e,
      modals: {
        modalCreate: {
          status: false,
          data: {
            question: "",
            questionFormat: "",
            options: [
              {
                id: 1,
                label: "",
              },
              {
                id: 2,
                label: "",
              },
            ],
          },
        },
        modalEdit: {
          status: false,
          data: {
            id: "",
            question: "",
            questionFormat: "",
            options: [
              {
                id: 1,
                label: "",
              },
              {
                id: 2,
                label: "",
              },
            ],
          },
        },
      },
      questionTypes: [
        { id: "short", label: "Text Singkat" },
        { id: "paragraph", label: "Text Paragraf" },
        { id: "single-choice", label: "Pilih Salah Satu" },
        { id: "multiple-choice", label: "Pilihan Ganda" },
      ],
      listForms: [],
      url: `${process.env.VUE_APP_API}/api/v1/dashboard/e/custom-form`,
      authToken: "Bearer " + JSON.parse(localStorage.getItem("token")),
    };
  },
  methods: {
    getData: function () {
      if (JSON.parse(localStorage.getItem("token"))) {
        axios
          .get(`${this.url}/${this.eventId}`, {
            headers: { Authorization: this.authToken },
          })
          .then(
            (res) => {
              if (res.data) {
                this.listForms = res.data.data;
              }
            },
            (err) => {
              if (err.response.data.message) {
                sweatalert.showAlert("error", err.response.data.message);
              } else {
                sweatalert.showAlert("error", "Maaf telah terjadi kesalahan");
              }
            }
          );
      }
    },
    editForm: function (formId) {
      var selectedForms = this.listForms.find((item) => item.id == formId);
      this.modals.modalEdit.data.id = selectedForms.id;
      this.modals.modalEdit.data.question = selectedForms.name;
      this.modals.modalEdit.data.questionFormat = selectedForms.type;
      this.modals.modalEdit.data.options = selectedForms.options;

      this.modals.modalEdit.status = true;
    },
    saveQuestion: function () {
      const data = {
        event_id: this.eventId,
        question: this.modals.modalCreate.data.question,
        questionFormat: this.modals.modalCreate.data.questionFormat,
        options: this.modals.modalCreate.data.options,
      };

      axios
        .post(this.url, data, {
          headers: { Authorization: this.authToken },
        })
        .then(
          (res) => {
            if (res.data.success) {
              this.modals.modalCreate.status = false;
              sweatalert.showAlert("success", res.data.message);
              this.listForms = res.data.data;

              this.modals.modalCreate.data.question = "";
              this.modals.modalCreate.data.questionFormat = "";
              this.modals.modalCreate.data.options = [
                {
                  id: 1,
                  label: "",
                },
                {
                  id: 2,
                  label: "",
                },
              ];
              this.reloadPage();
            }
          },
          (err) => {
            if (err.response.data.message) {
              sweatalert.showAlert("error", err.response.data.message);
            } else {
              sweatalert.showAlert("error", "Maaf telah terjadi kesalahan");
            }
          }
        );
    },
    updateQuestion: function () {
      const data = {
        question: this.modals.modalEdit.data.question,
        questionFormat: this.modals.modalEdit.data.questionFormat,
        options: this.modals.modalEdit.data.options,
      };

      axios
        .post(`${this.url}/${this.modals.modalEdit.data.id}`, data, {
          headers: { Authorization: this.authToken },
        })
        .then(
          (res) => {
            if (res.data.success) {
              this.modals.modalEdit.status = false;
              sweatalert.showAlert("success", res.data.message);
              this.listForms = res.data.data;

              this.reloadPage();
            }
          },
          (err) => {
            if (err.response.data.message) {
              sweatalert.showAlert("error", err.response.data.message);
            } else {
              sweatalert.showAlert("error", "Maaf telah terjadi kesalahan");
            }
          }
        );
    },
    deleteTicket: function (formId) {
      let text = "Apakah anda ingin menghapus pertanyaan ini?";

      if (confirm(text) == true) {
        axios
          .get(`${this.url}/delete/${formId}`, {
            headers: { Authorization: this.authToken },
          })
          .then(
            (res) => {
              if (res.data.success) {
                sweatalert.showAlert("success", res.data.message);
                this.listForms = res.data.data;

                this.reloadPage();
              }
            },
            (err) => {
              if (err.response.data.message) {
                sweatalert.showAlert("error", err.response.data.message);
              } else {
                sweatalert.showAlert("error", "Maaf telah terjadi kesalahan");
              }
            }
          );
      }
    },
    addNewOption: function () {
      let id = this.modals.modalCreate.data.options.length + 1;

      this.modals.modalCreate.data.options.push({
        id: id,
        label: "",
      });

      this.updatedOption();
    },
    deleteOption: function (idOption) {
      var lengthOption = this.modals.modalCreate.data.options.length;

      if (lengthOption > 1) {
        this.modals.modalCreate.data.options =
          this.modals.modalCreate.data.options.filter(function (element) {
            return element.id !== idOption;
          });

        this.updatedOption();
      } else {
        this.updatedOption();
      }
    },
    updatedOption: function () {
      if (this.modals.modalCreate.data.options.length == 1) {
        var elemnOption = document.getElementsByClassName(`del-option`);

        [].forEach.call(elemnOption, function (el) {
          el.classList.remove("text-danger");
          el.classList.remove("cursor-pointer");

          el.classList.add("text-muted");
        });
      }

      if (this.modals.modalCreate.data.options.length > 1) {
        var elemnOption = document.getElementsByClassName(`del-option`);

        [].forEach.call(elemnOption, function (el) {
          el.classList.remove("text-muted");

          el.classList.add("text-danger");
          el.classList.add("cursor-pointer");
        });
      }
    },
    addNewOptionEdit: function () {
      let id = this.modals.modalEdit.data.options.length + 1;

      this.modals.modalEdit.data.options.push({
        id: id,
        label: "",
      });

      this.updatedOption();
    },
    deleteOptionEdit: function (idOption) {
      var lengthOption = this.modals.modalEdit.data.options.length;

      if (lengthOption > 1) {
        this.modals.modalEdit.data.options =
          this.modals.modalEdit.data.options.filter(function (element) {
            return element.id !== idOption;
          });

        this.updatedOptionEdit();
      } else {
        this.updatedOptionEdit();
      }
    },
    updatedOptionEdit: function () {
      if (this.modals.modalEdit.data.options.length == 1) {
        var elemnOption = document.getElementsByClassName(`del-option-edit`);

        [].forEach.call(elemnOption, function (el) {
          el.classList.remove("text-danger");
          el.classList.remove("cursor-pointer");

          el.classList.add("text-muted");
        });
      }

      if (this.modals.modalEdit.data.options.length > 1) {
        var elemnOption = document.getElementsByClassName(`del-option-edit`);

        [].forEach.call(elemnOption, function (el) {
          el.classList.remove("text-muted");

          el.classList.add("text-danger");
          el.classList.add("cursor-pointer");
        });
      }
    },
    requireChange: function (formId) {
      const selectedForm = this.listForms.find((x) => x.id == formId);
      const data = {
        required: selectedForm.required,
      };

      axios
        .post(`${this.url}/required/${formId}`, data, {
          headers: { Authorization: this.authToken },
        })
        .then(
          (res) => {
            if (res.data.success) {
              sweatalert.showAlert("success", res.data.message);
              this.listForms = res.data.data;

              this.reloadPage();
            }
          },
          (err) => {
            if (err.response.data.message) {
              sweatalert.showAlert("error", err.response.data.message);
            } else {
              sweatalert.showAlert("error", "Maaf telah terjadi kesalahan");
            }
          }
        );
    },
    reloadPage: debounce((e) => {
      // location.reload();
    }, 1000),
  },
};
</script>
<style>
.table-ticket td {
  border-top: none !important;
  border-bottom: 1px solid #dbdae3;
  margin-bottom: 20px;
}
</style>
