<template>
  <section class="section section-shaped section-lg my-0">
    <div class="shape shape-style-1 bg-gradient-default">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
    <div class="container pt-lg-md">
      <div class="row justify-content-center">
        <div class="col-lg-5">
          <card
            type="secondary"
            shadow
            header-classes="bg-white pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0"
          >
            <div>
              <div class="text-muted text-center mb-3">
                <h1 class="heading">Login</h1>
              </div>
              <form role="form" @submit.prevent="login">
                <base-input
                  alternative
                  class="mb-3"
                  placeholder="Email"
                  type="email"
                  v-model="email"
                  :required="true"
                  addon-left-icon="ni ni-email-83"
                >
                </base-input>
                <base-input
                  alternative
                  type="password"
                  placeholder="Password"
                  v-model="password"
                  :required="true"
                  addon-left-icon="ni ni-lock-circle-open"
                >
                </base-input>
                <router-link :to="{ name: 'forgotPassword', params: {} }">
                  Lupa Password?
                </router-link>
                <div class="text-center">
                  <base-button nativeType="submit" type="primary" class="my-4"
                    >Masuk</base-button
                  >
                </div>
              </form>
            </div>
          </card>
          <div class="row mt-3">
            <!-- <div class="col-6">
              <a href="#" class="text-light">
                <small>Forgot password?</small>
              </a>
            </div> -->
            <div class="col-6 text-left">
              <router-link class="text-light" to="/register">
                <small>Buat akun baru</small>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from "axios";
import { storeAuth } from "@/reactivities/storeAuth.js";
import { sweatalert } from "@/reactivities/sweatalert.js";

export default {
  data: () => ({
    email: "",
    password: "",
    remeberme: true,
    submitted: false,
    storeAuth,
    urlPost: `${process.env.VUE_APP_API}/api/v1/login`,
  }),
  computed: {
    loggedIn() {
      return JSON.parse(localStorage.getItem("token"));
    },
  },
  mounted() {
    if (this.loggedIn) {
      this.$router.push({ name: "landing" });
    }
  },
  methods: {
    login: function () {
      axios
        .post(this.urlPost, {
          email: this.email,
          password: this.password,
        })
        .then(
          (res) => {
            if (res) {
              sweatalert.showAlert("success", res.data.message);

              localStorage.setItem("token", JSON.stringify(res.data.token));
              localStorage.setItem(
                "user",
                JSON.stringify({
                  name: res.data.data.name,
                  full_name: res.data.data.full_name,
                  first_name: res.data.data.first_name,
                  last_name: res.data.data.last_name,
                  email: res.data.data.email,
                })
              );

              storeAuth.isLogin = true;
              storeAuth.authToken = "Bearer " + res.data.token;
              storeAuth.user.full_name = res.data.data.full_name;

              setTimeout(() => this.$router.push({ name: "landing" }), 2000);
            } else {
              sweatalert.showAlert(
                "error",
                "Maaf ada kesalahan. Mohon muat ulang."
              );
            }
          },
          (err) => {
            if (err.response.data.message) {
              sweatalert.showAlert("error", err.response.data.message);
            } else {
              sweatalert.showAlert("error", "Maaf telah terjadi kesalahan");
            }
          }
        );
    },
  },
};
</script>
<style>
.base-notification {
  position: absolute;
  top: 50px;
  right: 10px;
  min-width: 450px;
  z-index: 9999;
}
</style>