<template>
  <header class="header-global">
  </header>
</template>
<script>
import BaseNav from "@/components/BaseNav";
import axios from "axios";
import { storeAuth } from "@/reactivities/storeAuth.js";
import { sweatalert } from "@/reactivities/sweatalert.js";
import debounce from "lodash.debounce";

export default {
  data: () => ({
    email: "",
    password: "",
    remeberme: true,
    submitted: false,
    full_name: "",
    isLogin: false,
    storeAuth,
    url: `${process.env.VUE_APP_API}/api/v1/logout`,
    authToken: "",
  }),
  computed: {
    loggedIn() {
      return JSON.parse(localStorage.getItem("token"));
    },
  },
  mounted() {
    if (this.loggedIn) {
      storeAuth.isLogin = true;
      storeAuth.full_name = JSON.parse(localStorage.getItem("user")).full_name;
      storeAuth.authToken =
        "Bearer " + JSON.parse(localStorage.getItem("token"));
    }
  },
  components: {},
  methods: {
    closeMenu: function () {
      this.$refs.children.toggled = false;
    },
    logout: function () {
      if (JSON.parse(localStorage.getItem("token"))) {
        axios
          .get(this.url, {
            headers: { Authorization: storeAuth.authToken },
          })
          .then(
            (res) => {
              if (res.data.success) {
                sweatalert.showAlert("success", res.data.message);

                this.logoutUser();
              }
            },
            (err) => {
              if (err.response.data.message) {
                sweatalert.showAlert("error", err.response.data.message);

                if (err.response.status == 401) {
                  this.logoutUser();
                }
              } else {
                sweatalert.showAlert("error", "Maaf telah terjadi kesalahan");
              }
            }
          );
      }
    },
    logoutUser: debounce((e) => {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      storeAuth.isLogin = false;
      storeAuth.user.full_name = null;
      this.$router.push({ name: "login" });
    }, 3000),
  },
};
</script>

<style>
@media screen and (min-width: 900px) {
  .user-icon {
    color: #fff;
  }
  .icon-navbar {
    color: #fff;
  }
}

@media screen and (max-width: 768px) {
  .user-icon {
    color: #333;
  }

}
</style>
