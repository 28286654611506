<template>
  <div>
    <section class="section section-shaped section-lg my-0">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h3><b>Gambar Utama Acara</b></h3>
            <p>
              Ini adalah gambar pertama yang akan dilihat peserta di bagian atas
              cantuman Anda.
            </p>
          </div>
        </div>
        <form role="form" @submit.prevent="submitForm" ref="formDetail">
          <base-input
            id="input-image-cover"
            class="d-none"
            type="file"
            accept="image/png, image/jpeg, image/bmp"
            ref="fileImageCover"
            @change="onChangeImageCover"
          >
          </base-input>

          <base-input type="hidden" v-model="eventId"> </base-input>

          <div class="row">
            <div class="col-md-8 mb-3">
              <div
                class="card p-5"
                @click="chooseFilesImageCover"
                v-if="!imageCoverUrl"
              >
                <div class="text-center">
                  <i class="fa fa-image text-primary icon-image"></i>
                  <p class="heading mb-0">
                    Klik untuk menambahkan Gambar Utama Acara
                  </p>
                  <p>JPEG atau PNG, tidak lebih besar dari 10MB.</p>
                </div>
              </div>

              <img
                id="image-cover"
                class=""
                :src="imageCoverUrl"
                alt=""
                v-if="imageCoverUrl"
              />

              <base-button
                type="primary"
                @click="chooseFilesImageCover"
                class="mt-5"
                v-if="imageCoverUrl"
                >Ganti Gambar</base-button
              >
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-md-8">
              <h5><b>Deskripsi</b></h5>
              <p>
                Tambahkan lebih banyak detail ke acara Anda seperti jadwal,
                sponsor, atau tamu unggulan Anda.
              </p>
            </div>
            <div class="col-md-8">
              <label for="" class="mb-0">Ringkasan</label>
              <base-input
                alternative
                class="border-white mb-4"
                inputClasses="p-0"
                type="text"
                style="box-shadow: none !important"
                placeholder="Ringkasan"
                v-model="summary"
                :required="true"
              >
              </base-input>
              <vue-editor v-model="desc" class="mt-3" />
              <hr class="m-0 mt-3" />
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-md-10">
              <router-link
                class="text-light"
                :to="{ name: 'editEvent', params: { e: eventId } }"
              >
                <base-button type="secondary" class="mr-4">Kembali</base-button>
              </router-link>
              <base-button type="primary" class="mr-0" @click="submitForm">
                Simpan Perubahan
              </base-button>
            </div>
          </div>
        </form>
      </div>
    </section>
  </div>
</template>
<script>
import { VueEditor } from "vue2-editor";
import axios from "axios";
import { sweatalert } from "@/reactivities/sweatalert.js";

flatpickr(".datetimepicker", {
  enableTime: true,
  dateFormat: "Y-m-d H:i",
});

export default {
  components: {
    VueEditor,
  },
  data() {
    return {
      eventId: this.$route.params.e,
      summary: "",
      desc: "<p>Deskripsi</p>",
      imageCover: "",
      imageCoverUrl: "",
      authToken: "Bearer " + JSON.parse(localStorage.getItem("token")),
      url: `${process.env.VUE_APP_API}/api/v1/dashboard/e/detail`,
      urlGetEvent: `${process.env.VUE_APP_API}/api/v1/dashboard/events?id=${this.$route.params.e}`,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData: function () {
      axios
        .get(this.urlGetEvent, {
          headers: { Authorization: this.authToken },
        })
        .then(
          (res) => {
            if (res.data.data) {
              this.summary = res.data.data.summary;
              this.desc = res.data.data.desc;
              this.imageCoverUrl = res.data.data.image_url;
            }
          },
          (err) => {
            sweatalert.showAlert("error", "Galat! Error silahkan coba lagi");
          }
        );
    },
    chooseFilesImageCover: function () {
      document.getElementById("input-image-cover").click();
    },
    onChangeImageCover: function (e) {
      const file = e.target.files[0];

      const image = URL.createObjectURL(file);
      this.imageCoverUrl = image;
      this.imageCover = file;
    },
    submitForm: function () {
      const myFormData = new FormData();

      myFormData.append("event_id", this.eventId);
      myFormData.append("image", this.imageCover);
      myFormData.append("summary", this.summary);
      myFormData.append("desc", this.desc);

      axios
        .post(this.url, myFormData, {
          headers: {
            Authorization: this.authToken,
          },
        })
        .then(
          (res) => {
            if (res.data.success) {
              sweatalert.showAlert("success", res.data.message);

              this.$router.push({
                name: "createTicket",
                params: { e: this.eventId },
              });
            }
          },
          (err) => {
            if (err.response.data.message) {
              sweatalert.showAlert("error", err.response.data.message);
            } else {
              sweatalert.showAlert("error", "Maaf telah terjadi kesalahan");
            }
          }
        );
    },
  },
};
</script>
<style>
.icon-image {
  font-size: 40px !important;
  margin-bottom: 10px;
}
#image-cover {
  width: 100%;
  height: 300px;
}
</style>
