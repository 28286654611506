<template>
  <div>
    <section class="section section-shaped section-lg my-0">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="mb-5">
              <div class="pull-right">
                <router-link :to="{ name: 'newScanAttendance', params: {} }"
                  >New Scan</router-link
                >
              </div>
              <h3>Pindai Peserta</h3>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-8">
            <div class="card">
              <div class="row">
                <div class="col-md-5">
                  <img
                    :src="event.image_url"
                    class="img-center img-fluid"
                    style=""
                  />
                </div>
                <div class="col-md-7 p-4">
                  <p class="heading font-weight-bold mb-2">{{ event.title }}</p>
                  <small>
                    {{ event.formatted_from }} <br />
                    {{ event.location }}
                  </small>
                </div>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-md-12">
                <div class="pull-right">
                  <base-button
                    class="btn-1 float-right w-100"
                    type="primary"
                    @click="printTicket"
                    ><i class="fa fa-print"></i> Print Semua Data
                    Peserta</base-button
                  >
                </div>
                <h4>Daftar Peserta</h4>
                <p class="mb-3">Daftar peserta yang sudah berhasil pindai.</p>
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col" class="col-md-2">
                        <small>Transaksi</small>
                      </th>
                      <th scope="col" class="col-md-2">
                        <small>Pembeli</small>
                      </th>
                      <th scope="col" class="col-md-2">
                        <small>Tanggal</small>
                      </th>
                      <th scope="col" class="col-md-2">
                        <small>Status</small>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="list in participants" :key="list.id">
                      <td>
                        <p>#{{ list.id }}</p>
                      </td>
                      <td>
                        <span
                          >{{ list.participant.first_name }}
                          {{ list.participant.last__name }}</span
                        >
                      </td>
                      <td>
                        <span>{{ list.attended_at }}</span>
                      </td>
                      <td>
                        <span>{{ list.status_scan }}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div
              id="reader"
              class="card shadow-lg p-3 mb-5 bg-white rounded w-100"
            ></div>
          </div>
        </div>
      </div>
    </section>

    <modal
      :show="modalAttendanceInfo"
      body-classes="p-0"
      modal-classes="modal-dialog-centered"
    >
      <div class="container">
        <div class="row">
          <div class="col-md-12 mt-3 mb-3">
            <div class="card">
              <div class="card-body mb-3">
                <h5 class="card-title mt-3">Berhasil Melakukan Presensi</h5>
                <p class="card-text">
                  <b>Halo, {{ currentParticipant.nama }}</b> <br />
                  Selamat datang. Anda dinyatakan
                  <span class="text-success">Hadir</span> di acara
                  {{ event.title }}, pada {{ currentParticipant.waktu }}
                </p>

                <button
                  class="btn btn-outline-primary mt-3"
                  @click="modalAttendanceInfo = false"
                >
                  Tutup
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
import { Html5QrcodeScanner } from "html5-qrcode";
import { qrcodeCameraStatus } from "@/reactivities/qrcodeCameraStatus.js";
import Modal from "@/components/Modal.vue";

import axios from "axios";

export default {
  components: {
    Modal,
  },
  data() {
    return {
      modalAttendanceInfo: false,
      eventId: this.$route.params.e,
      event: "",
      participants: "",
      alreadyScan: false,
      urlScan: `${process.env.VUE_APP_API}/api/v1/dashboard/e/scan`,
      authToken: "Bearer " + JSON.parse(localStorage.getItem("token")),
      currentParticipant: {
        nama: "...",
        waktu: "...",
      },
    };
  },
  unmounted() {
    qrcodeCameraStatus.html5QrcodeScanner.clear();
  },
  created() {
    this.getData();
  },
  mounted() {
    qrcodeCameraStatus.html5QrcodeScanner = new Html5QrcodeScanner(
      "reader",
      { fps: 10, qrbox: { width: 250, height: 250 } },
      /* verbose= */ false
    );
    qrcodeCameraStatus.html5QrcodeScanner.render(
      this.onScanSuccess,
      this.onScanFailure
    );
  },
  methods: {
    printTicket: function () {
      const eventName = this.event.title.toLowerCase().replace(" ", "-");
      const filename = `tiket-${eventName}-${Date.now()}.pdf`;

      axios
        .get(`${this.urlScan}/data/${this.eventId}/print`, {
          headers: {
            Authorization: this.authToken,
            Accept: "application/json",
          },
          responseType: "blob",
        })
        .then(
          (response) => {
            if (response.data) {
              // var blobObj = new Blob([atob(response.data)], { type: "application/pdf" });

              // create file link in browser's memory
              const href = window.URL.createObjectURL(
                new Blob([response.data], { type: "application/pdf" })
              );

              // create "a" HTML element with href to file & click
              const link = document.createElement("a");
              link.href = href;
              link.setAttribute("download", filename); //or any other extension
              document.body.appendChild(link);
              link.click();
              // clean up "a" element & remove ObjectURL
              document.body.removeChild(link);
              URL.revokeObjectURL(href);
            }
          },
          (err) => {
            // sweatalert.showAlert("error", "Maaf telah terjadi kesalahan");
          }
        );
    },
    getData: function () {
      axios
        .get(
          `${process.env.VUE_APP_API}/api/v1/dashboard/e/scan/data/${this.$route.params.e}`,
          {
            headers: { Authorization: this.authToken },
          }
        )
        .then(
          (res) => {
            if (res.data.data) {
              this.event = res.data.data.event;
              this.participants = res.data.data.participants;
            }
          },
          (err) => {
            if (err.response.data.message) {
              sweatalert.showAlert("error", err.response.data.message);
            } else {
              sweatalert.showAlert("error", "Maaf telah terjadi kesalahan");
            }
          }
        );
    },
    scanSuccess: function ($pur_id) {
      let data = {
        event_id: this.eventId,
        purchased_id: $pur_id,
      };

      axios
        .post(this.urlScan, data, {
          headers: { Authorization: this.authToken },
        })
        .then(
          (res) => {
            if (res.data.success) {
              this.alreadyScan = false;

              this.event = res.data.data.event;
              this.participants = res.data.data.participants;

              let result = res.data.data.participants.filter((obj) => {
                return obj.id == $pur_id;
              });

              if (result) {
                this.currentParticipant.nama = result.participants.full_name;
                this.currentParticipant.waktu = result.attended_at;
              }
            }
          },
          (err) => {
            if (err.response.data.message) {
              sweatalert.showAlert("error", err.response.data.message);
            } else {
              sweatalert.showAlert("error", "Maaf telah terjadi kesalahan");
            }
          }
        );
    },
    onScanSuccess: function (decodedText, decodedResult) {
      // handle the scanned code as you like, for example:

      this.scanSuccess(decodedText);

      if (!this.alreadyScan) {
        this.alreadyScan = true;

        this.scanSuccess(decodedText);
      }
    },
    onScanFailure: function (decodedText, decodedResult) {},
  },
};
</script>
<style>
</style>
