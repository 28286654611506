<template>
  <div>
    <section class="section section-shaped section-lg my-0">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="mb-4">
              <h3>Transaksi</h3>
            </div>
            <div class="mt-3" style="overflow: auto;">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col" class="col-md-1">
                      <small>Transaksi</small>
                    </th>
                    <th scope="col" class="col-md-2"><small>Acara</small></th>
                    <th scope="col" class="col-md-2"><small>Pembeli</small></th>
                    <th scope="col" class="col-md-2"><small>Tanggal</small></th>
                    <th scope="col" class="col-md-2"><small>Status</small></th>
                    <th scope="col" class="col-md-2"><small>Total</small></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="list in listOrder" :key="list.order_id">
                    <td>
                      <p>#{{ list.order_id }}</p>
                    </td>
                    <td>
                      <span class="text-primary">{{
                        list.order_event_name
                      }}</span>
                    </td>
                    <td>
                      <span>{{ list.order_participant_name }}</span>
                    </td>
                    <td>
                      <span>{{ list.order_date }}</span>
                    </td>
                    <td>
                      <span style="text-transform: capitalize;">{{ list.order_status }}</span>
                    </td>
                    <td>
                      <div class="row">
                        <div class="col-md-8 col-12">
                          <span>{{ list.order_amount }}</span>
                        </div>
                        <div class="col-md-4 col-12 text-right">
                          <i
                            class="fa fa-eye cursor-pointer"
                            @click="showOrder(list.order_id)"
                          ></i>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="listOrder.length == 0">
                    <td colspan="3">
                      <p>Belum ada data transaksi</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import axios from "axios";

export default {
  components: {},
  created() {
    this.getData();
  },
  data() {
    return {
      authToken: "Bearer " + JSON.parse(localStorage.getItem("token")),
      urlGetOrder:
        `${process.env.VUE_APP_API}/api/v1/dashboard/transaction`,
      listOrder: "",
    };
  },
  methods: {
    getData: function () {
      axios
        .get(this.urlGetOrder, {
          headers: {
            Authorization: this.authToken,
          },
        })
        .then(
          (res) => {
            if (res.data.data) {
              this.listOrder = res.data.data;
            }
          },
          (err) => {
            if (err.response.data.message) {
              sweatalert.showAlert("error", err.response.data.message);
            } else {
              sweatalert.showAlert("error", "Maaf telah terjadi kesalahan");
            }
          }
        );
    },
    showOrder: function (orderId) {
      this.$router.push({ name: "detailOrder", params: { orderId: orderId } });
    },
  },
};
</script>
<style>
.cursor-pointer {
  cursor: pointer;
}
</style>
