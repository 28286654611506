<template>
  <div>
    <section class="section section-shaped section-lg my-0">
      <div class="container">
        <modal
          :show="modals.modal1"
          body-classes="p-0"
          modal-classes="modal-dialog-centered modal-xl"
        >
          <div class="row">
            <div class="col-md-12" style="padding-right: 0px !important">
              <card
                type="secondary"
                shadow
                header-classes="bg-white"
                body-classes="px-lg-5 py-lg-4 bg-white"
                footer-classes="bg-white"
                class="border-0"
              >
                <template v-slot:header>
                  <div class="modal-title text-center my-3">
                    <h4 class="">Detail Pesanan</h4>
                  </div>
                  <base-button
                    type="button"
                    class="btn btn-sm btn-secondary btn-icon-only rounded-circle mt-2"
                    style="
                      position: absolute;
                      top: 10px;
                      right: 20px;
                      z-index: 999;
                    "
                    @click="modals.modal1 = false"
                  >
                    <span class="btn-inner--icon"
                      ><i class="fa fa-close"></i
                    ></span>
                  </base-button>
                </template>
                <div>
                  <div class="container">
                    <div class="row text-left">
                      <div class="col-md-12">
                        <h4 class="font-weight-bold">Info Pembeli</h4>

                        <h5 class="heading mt-5">Informasi kontak</h5>
                        <div class="row">
                          <div class="col-md-6">
                            <label for="">Nama</label>
                            <p>{{ orderParticipantName }}</p>
                          </div>

                          <div class="col-md-6">
                            <label for="">Email</label>
                            <p>{{ orderParticipantEmail }}</p>
                          </div>
                        </div>

                        <hr />
                      </div>
                      <div class="col-md-12">
                        <h5 class="heading">Informasi Lainnya</h5>
                        <div class="row">
                          <div
                            class="col-md-12"
                            v-for="data in customFormData"
                            :key="data.id"
                          >
                            <label for=""> {{ data.label }} </label>
                            <p
                              v-if="data.type != 'multiple-choice'"
                              v-html="data.value"
                            ></p>
                            <div v-if="data.type == 'multiple-choice'">
                              <span
                                class="mr-2"
                                v-for="val in data.value"
                                :key="val.id"
                              >
                                {{ val.label }},
                              </span>
                            </div>
                          </div>
                        </div>

                        <hr />
                      </div>
                    </div>
                  </div>
                </div>
              </card>
            </div>

            <!-- Sidebar -->
            <!-- <div class="col-md-4" style="padding-left: 0px !important">
              <base-button
                type="button"
                class="btn btn-sm btn-secondary btn-icon-only rounded-circle"
                style="position: absolute; top: 10px; right: 20px; z-index: 999"
                @click="modals.modal1 = false"
              >
                <span class="btn-inner--icon"><i class="fa fa-close"></i></span>
              </base-button>
            </div> -->
          </div>
        </modal>
        <modal
          :show="modals.modalCompletedOrder"
          body-classes="p-0"
          modal-classes="modal-dialog-centered"
        >
          <div class="row">
            <!-- Ticket Section -->
            <div class="col-md-12" style="padding-right: 0px !important">
              <card
                type="secondary"
                shadow
                header-classes="bg-white"
                body-classes="px-lg-1 py-lg-3 bg-white"
                footer-classes="bg-white"
                class="border-0"
              >
                <div>
                  <div class="container">
                    <div class="row text-left border-bottom mb-3">
                      <div class="col-md-12">
                        <h5 class="mb-3">Bukti Transfer</h5>
                        <hr class="mb-3 mt-3" />
                      </div>
                      <div class="col-md-12 text-center">
                        <img
                          style="width: 265px"
                          :src="orderImageTransfer"
                          alt=""
                        />
                      </div>
                      <div class="col-md-6">
                        <base-button
                          type="secondary"
                          class="mr-3 w-100 my-4"
                          @click="modals.modalCompletedOrder = false"
                          >Batal</base-button
                        >
                      </div>
                      <div class="col-md-6">
                        <base-button
                          type="primary"
                          class="mr-3 w-100 my-4"
                          @click="completedOrder"
                          >Terima Pembayaran</base-button
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </card>
            </div>
          </div>
        </modal>

        <div class="row">
          <div class="col-md-12">
            <div class="mb-4">
              <router-link :to="{ name: 'listOrder', params: {} }">
                <small><i class="fa fa-angle-left"></i> Transaksi</small>
              </router-link>
            </div>
          </div>
          <div class="col-md-4">
            <div class="mb-3">
              <h3>
                <b>Order #{{ orderId }}</b>
              </h3>
            </div>
          </div>
          <div class="col-md-8 text-right">
            <p
              class="text-primary font-weight-bold cursor-pointer"
              @click="printTicket"
              v-if="orderStatus == 'completed'"
            >
              <i class="fa fa-print mr-2"></i> Cetak Tiket
            </p>
            <p
              class="text-primary font-weight-bold cursor-pointer"
              @click="openModalCompletedOrder"
              v-if="orderStatus == 'paid'"
            >
              <i class="fa fa-send mr-2"></i> Terima pembayaran
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="shadow p-3 mb-5 bg-white rounded">
              <div class="row">
                <div class="col-md-5">
                  <img
                    :src="event.image_url"
                    class="img-center img-fluid"
                    style=""
                  />
                  <div class="row">
                    <div class="col-md-12">
                      <div class="p-3">
                        <p class="heading font-weight-bold mb-4">
                          {{ event.title }} <br />
                          <router-link
                            :to="{ name: 'dashboardEvents', params: {} }"
                          >
                            <small class="font-weight-bold"
                              >Edit acara ini</small
                            >
                          </router-link>
                        </p>
                        <p class="font-weight-bold mb-1">
                          <i class="fa fa-map-marker mr-2"></i>
                          {{ event.location }}
                        </p>
                        <p class="font-weight-bold">
                          <i class="fa fa-calendar mr-2"></i>
                          {{ event.formated_from_date }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-7 p-4">
                  <p class="heading font-weight-bold mb-4">Detail Transaksi</p>
                  <div class="row">
                    <div class="col-md-4">
                      <small>Nama Pembeli</small> <br />
                      <p class="text-default heading font-weight-bold">
                        {{ orderParticipantName }}
                      </p>
                    </div>
                    <div class="col-md-4">
                      <small>Total Transaksi</small> <br />
                      <p class="text-default heading font-weight-bold">
                        Rp.{{ formatedCurrency(orderAmount) }}
                      </p>
                    </div>
                    <div class="col-md-4">
                      <small>Tanggal Pembeliaan</small> <br />
                      <p class="text-default heading font-weight-bold">
                        {{ orderDate }}
                      </p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <p
                        class="font-weight-bold cursor-pointer"
                        @click="this.modals.modal1 = true"
                      >
                        Lihat Detail
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="mb-3">
              <h4><b>Peserta</b></h4>
            </div>
            <table class="table table-sm">
              <thead>
                <tr class="table-light">
                  <th scope="col" class="col-md-3">
                    <small class="font-weight-bold">QrCode</small>
                  </th>
                  <th scope="col" class="col-md-3">
                    <small class="font-weight-bold">Nama</small>
                  </th>
                  <th scope="col" class="col-md-3">
                    <small class="font-weight-bold">Tipe Tiket</small>
                  </th>
                  <th scope="col" class="col-md-3">
                    <small class="font-weight-bold">Harga</small>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="list in purchasedTickets" :key="list.id">
                  <td>
                    <p>
                      Status Presensi <br />
                      <small>{{ list.status_scan }}</small>
                    </p>
                  </td>
                  <td>
                    <p>{{ list.participant.full_name }}</p>
                  </td>
                  <td>
                    <p>{{ list.ticket.name }}</p>
                  </td>
                  <td>
                    <div class="row">
                      <div class="col-md-8">
                        <p>Rp.{{ formatedCurrency(list.ticket.price) }}</p>
                      </div>
                      <!-- <div class="col-md-4">
                        <a href="" class="text-default"
                          ><i class="fa fa-eye"></i
                        ></a>
                      </div> -->
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import axios from "axios";
import Modal from "@/components/Modal.vue";
import { sweatalert } from "@/reactivities/sweatalert.js";

export default {
  components: {
    Modal,
  },
  mounted() {
    this.getData();
  },
  data() {
    return {
      modals: {
        modal1: false,
        modalCompletedOrder: false,
      },
      orderId: this.$route.params.orderId,
      authToken: "Bearer " + JSON.parse(localStorage.getItem("token")),
      urlGetDetailOrder: `${process.env.VUE_APP_API}/api/v1/dashboard/transaction/`,
      urlGetCustomData: `${process.env.VUE_APP_API}/api/v1/dashboard/transaction/`,
      orderParticipantName: "",
      orderParticipantEmail: "",
      orderDate: "",
      orderAmount: 0,
      orderStatus: "",
      orderImageTransfer: "",
      event: "",
      purchasedTickets: "",
      customFormData: null,
      urlPrintTicket: `${process.env.VUE_APP_API}/api/v1/print/ticket/`,
    };
  },
  methods: {
    getData: function () {
      axios
        .get(`${this.urlGetDetailOrder}${this.orderId}`, {
          headers: {
            Authorization: this.authToken,
          },
        })
        .then(
          (res) => {
            if (res.data.data) {
              this.orderParticipantName = res.data.data.order_participant_name;
              this.orderParticipantEmail =
                res.data.data.order_participant_email;
              this.orderDate = res.data.data.order_date;
              this.orderAmount = res.data.data.order_amount;
              this.orderStatus = res.data.data.order_status;
              this.orderImageTransfer = res.data.data.order_image_transfer;
              this.event = res.data.data.order_event;
              this.purchasedTickets = res.data.data.purchased_tickets;
              this.customFormData = res.data.data.custom_form;
            }
          },
          (err) => {
            if (err.response.data.message) {
              sweatalert.showAlert("error", err.response.data.message);
            } else {
              sweatalert.showAlert("error", "Maaf telah terjadi kesalahan");
            }
          }
        );
    },
    printTicket: function () {
      const participantName = `${this.orderParticipantName.toLowerCase()}-` ?? '';
      const eventName = this.event.title.toLowerCase().replace(" ", "-");
      const filename = `tiket-${participantName}${eventName}-${Date.now()}.pdf`;

      axios
        .get(`${this.urlPrintTicket}${this.orderId}`, {
          headers: {
            Authorization: this.authToken,
            Accept: "application/binary",
          },
          responseType: "blob",
        })
        .then(
          (response) => {
            if (response.data) {
              // var blobObj = new Blob([atob(response.data)], { type: "application/pdf" });

              // create file link in browser's memory
              const href = window.URL.createObjectURL(
                new Blob([response.data], { type: "application/pdf" })
              );

              // create "a" HTML element with href to file & click
              const link = document.createElement("a");
              link.href = href;
              link.setAttribute("download", filename); //or any other extension
              document.body.appendChild(link);
              link.click();
              // clean up "a" element & remove ObjectURL
              document.body.removeChild(link);
              URL.revokeObjectURL(href);
            }
          },
          (err) => {
            // sweatalert.showAlert("error", "Maaf telah terjadi kesalahan");
          }
        );
    },
    formatedCurrency: function (number) {
      var reverse = number.toString().split("").reverse().join(""),
        value = reverse.match(/\d{1,3}/g);
      value = value.join(".").split("").reverse().join("");

      return value;
    },
    openModal: function () {
      if (this.customFormData == null) {
        axios
          .get(`${this.urlGetCustomData}${this.orderId}`, {
            headers: {
              Authorization: this.authToken,
            },
          })
          .then(
            (res) => {
              if (res.data.data) {
                this.customFormData = res.data.data;

                this.modals.modal1 = true;
              }
            },
            (err) => {
              if (err.response.data.message) {
                sweatalert.showAlert("error", err.response.data.message);
              } else {
                sweatalert.showAlert("error", "Maaf telah terjadi kesalahan");
              }
            }
          );
      }
    },
    openModalCompletedOrder: function () {
      this.modals.modalCompletedOrder = true;
    },
    completedOrder: function () {
      if (confirm("Apakah anda yakin ingin mengkonfirmasi pembayaran ini?")) {
        const data = {
          order_id: this.orderId,
        };

        axios
          .post(`${this.urlGetDetailOrder}${this.orderId}/completed`, data, {
            headers: {
              Authorization: this.authToken,
            },
          })
          .then((res) => {
            if (res.data.data) {
              this.modals.modalCompletedOrder = false;
              this.getData();
              sweatalert.showAlert(
                "success",
                "Pembayaran berhasil dikonfirmasi"
              );
            }
          });
      }
    },
  },
};
</script>
<style>
</style>
