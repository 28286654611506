<template>
  <div class="profile-page">
    <section class="section-profile-cover section-shaped my-0">
      <div class="shape shape-style-1 shape-primary shape-skew alpha-4">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </section>
    <section class="section section-skew">
      <div class="container">
        <card shadow class="card-profile mt--500" no-body>
          <div class="px-4">
            <div class="row justify-content-center">
              <div
                class="col-lg-4 order-lg-3 text-lg-right align-self-lg-center"
              >
                <div class="card-profile-actions mt-lg-0">
                  <base-button
                    block
                    type="success"
                    size="lg"
                    class="mr-4 w-100"
                    @click="openModalTicket"
                  >
                    Register
                  </base-button>

                  <modal
                    :show="modals.modal1"
                    body-classes="p-0"
                    modal-classes="modal-dialog-centered modal-xl"
                  >
                    <div class="row">
                      <!-- Ticket Section -->
                      <div
                        v-if="this.modals.layers.ticket"
                        class="col-md-8"
                        style="padding-right: 0px !important"
                      >
                        <card
                          type="secondary"
                          shadow
                          header-classes="bg-white"
                          body-classes="px-lg-5 py-lg-div5 bg-white"
                          footer-classes="bg-white"
                          class="border-0"
                        >
                          <template v-slot:header>
                            <div class="modal-title text-center my-3">
                              <h4 class="">{{ eventData.title }}</h4>
                              <p class="heading mb-0">
                                {{ eventData.formated_from_date }}
                              </p>
                            </div>
                          </template>
                          <div>
                            <div class="container">
                              <div
                                class="row text-left border-bottom mb-3"
                                v-for="ticket in ticketDatas"
                                :key="ticket.id"
                              >
                                <div class="col-md-8 col-sm-2">
                                  <h5 class="mb-0">{{ ticket.name }}</h5>
                                  <p class="mb-0">{{ ticket.desc }}</p>
                                  <h6 class="font-weight-bold my-3">
                                    {{ ticket.formatted_price }}
                                  </h6>
                                  <p class="text-small text-muted">
                                    {{ ticket.formatted_sales_end }}
                                  </p>
                                </div>
                                <div
                                  class="col-md-4 col-sm-12 mb-2"
                                  v-if="ticket.status == 'on_sale'"
                                >
                                  <v-select
                                    label="qty"
                                    class="mt-2 float-right"
                                    :filterable="false"
                                    :options="ticket.avaliable_tickets"
                                    placeholder="1"
                                    v-model="ticket.purchase_qty"
                                    @option:selected="updateSummary(ticket.id)"
                                    :clearable="false"
                                  >
                                  </v-select>
                                </div>
                                <div
                                  class="col-md-4 col-sm-12 mb-2 text-right text-danger font-weight-bold"
                                  v-if="ticket.status == 'sold_out'"
                                >
                                  {{ ticket.status_message }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <template v-slot:footer>
                            <base-button
                              type="success"
                              class="mr-3"
                              @click="submitTicket"
                              :disabled="isDisabled"
                              >Register</base-button
                            >
                          </template>
                        </card>
                      </div>

                      <!-- Checkout Section -->
                      <div
                        class="col-md-8"
                        style="padding-right: 0px !important"
                        v-if="this.modals.layers.checkout"
                      >
                        <card
                          type="secondary"
                          shadow
                          header-classes="bg-white"
                          body-classes="px-lg-5 py-lg-4 bg-white"
                          footer-classes="bg-white"
                          class="border-0"
                          v-if="isLogin"
                        >
                          <template v-slot:header>
                            <div class="modal-title text-center">
                              <h4 class="">{{ eventData.title }}</h4>
                              <p class="heading mb-0">
                                {{ eventData.formated_from_date }}
                              </p>
                            </div>
                          </template>
                          <div>
                            <form
                              ref="form"
                              action="#"
                              method="post"
                              role="form"
                              class="row"
                              @submit.prevent="checkForm"
                            >
                              <div class="container">
                                <!-- Primary Form -->
                                <div class="row text-left mb-3">
                                  <div class="col-md-12 mb-2">
                                    <p
                                      class="cursor-pointer text-warning w-25"
                                      @click="changeLayer('ticket')"
                                    >
                                      <i class="fa fa-angle-left"></i> Kembali
                                    </p>

                                    <h4 class="font-weight-bold">
                                      Informasi Kontak
                                    </h4>
                                  </div>
                                  <div class="col-md-6">
                                    <base-input
                                      alternative
                                      class="mb-3"
                                      placeholder="First Name"
                                      addon-left-icon="fa fa-user"
                                      :required="true"
                                      v-model="primaryForm.firstName"
                                    >
                                    </base-input>
                                  </div>
                                  <div class="col-md-6">
                                    <base-input
                                      alternative
                                      class="mb-3"
                                      placeholder="Last Name"
                                      addon-left-icon="fa fa-user"
                                      :required="false"
                                      v-model="primaryForm.lastName"
                                    >
                                    </base-input>
                                  </div>
                                  <div class="col-md-12">
                                    <base-input
                                      alternative
                                      class="mb-3"
                                      type="email"
                                      ref="email"
                                      placeholder="Email"
                                      addon-left-icon="ni ni-email-83"
                                      :required="true"
                                      v-model="primaryForm.email"
                                    >
                                    </base-input>
                                  </div>
                                </div>

                                <!-- Registration Form Here! -->
                                <div
                                  class="row text-left mb-3"
                                  v-if="customFormDatas.length"
                                >
                                  <div
                                    v-for="data in customFormDatas"
                                    :key="data.id"
                                    :class="[
                                      data.type == 'short'
                                        ? 'col-md-6'
                                        : 'col-md-12',
                                    ]"
                                  >
                                    <div v-if="data.type == 'short'">
                                      <h5>{{ data.name }}</h5>
                                      <base-input
                                        alternative
                                        class="mb-3"
                                        :placeholder="data.label"
                                        addon-left-icon="fa fa-user"
                                        v-model="data.value"
                                        :required="data.required"
                                      >
                                      </base-input>
                                    </div>

                                    <div v-if="data.type == 'paragraph'">
                                      <h5>{{ data.name }}</h5>
                                      <vue-editor
                                        v-model="data.value"
                                        :editor-toolbar="customToolbar"
                                        :required="data.required"
                                        class="my-3"
                                      />
                                    </div>

                                    <div v-if="data.type == 'single-choice'">
                                      <h5>{{ data.name }}</h5>
                                      <base-radio
                                        v-for="option in data.options"
                                        :key="option"
                                        :name="option.label"
                                        class="mb-3"
                                        v-model="data.value"
                                        :required="false"
                                      >
                                        {{ option.label }}
                                      </base-radio>
                                    </div>

                                    <div
                                      class="w-100 mt-2"
                                      v-if="data.type == 'multiple-choice'"
                                    >
                                      <h5 for="" class="mb-0 w-100">
                                        {{ data.name }}
                                      </h5>
                                      <v-select
                                        class="mt-2 mb-3 w-100 float-left"
                                        :filterable="false"
                                        :options="data.options"
                                        v-model="data.value"
                                        :clearable="false"
                                        :reduce="data.options.label"
                                        :getOptionLabel="data.options.label"
                                        :required="data.required"
                                        :multiple="true"
                                      >
                                      </v-select>
                                    </div>
                                  </div>
                                </div>
                                  <div class="col-md-12 mt-5">
                                    <base-button
                                      type="success"
                                      class="mr-3"
                                      nativeType="submit"
                                      >Lanjut Ke Pembayaran</base-button
                                    >
                                  </div>
                              </div>
                            </form>
                          </div>
                          <!-- <div slot="footer">
                            <base-button
                              type="submit"
                              class="mr-3"
                              @click="checkout"
                              :disabled="!validationStatus"
                              >Register</base-button
                            >
                          </div> -->
                        </card>

                        <card
                          type="secondary"
                          shadow
                          header-classes="bg-white"
                          body-classes="px-lg-5 py-lg-4 bg-white"
                          footer-classes="bg-white"
                          class="border-0"
                          v-if="!isLogin"
                        >
                          <div>
                            <div class="row">
                              <div class="col-md-12 text-left">
                                <h3>Login</h3>
                                <p>
                                  Peserta diwajibkan untuk login terlebih
                                  dahulu.
                                </p>
                                <router-link class="text-light" to="/login">
                                  <base-button type="warning"
                                    >Login</base-button
                                  >
                                </router-link>
                              </div>
                            </div>
                          </div>
                        </card>
                      </div>

                      <!-- Payment Section -->
                      <div
                        class="col-md-8"
                        style="padding-right: 0px !important"
                        v-if="this.modals.layers.payment"
                      >
                        <card
                          type="secondary"
                          shadow
                          header-classes="bg-white"
                          body-classes="px-lg-5 py-lg-4 bg-white"
                          footer-classes="bg-white"
                          class="border-0"
                        >
                          <template v-slot:header>
                            <div class="modal-title text-center mb-3">
                              Terimakasih sudah mendaftar!
                            </div>
                          </template>
                          <div>
                            <div class="container">
                              <div class="row text-left">
                                <div class="col-md-12">
                                  <p>
                                    <b>Menunggu Pembayaran!</b> <br />
                                    Mohon selesaikan pembayaran Anda sebelum
                                    pukul <b>11.00 WIB</b> dengan rincian
                                    sebagai berikut.
                                  </p>

                                  <div class="mb-2">
                                    <div class="row">
                                      <div class="col-md-12">
                                        <dl
                                          v-for="itemSummary in summaryDatas.data"
                                          :key="itemSummary.id"
                                        >
                                          <dt>
                                            {{ itemSummary.qty }} x
                                            {{ itemSummary.name }}
                                          </dt>
                                          <dd>{{ itemSummary.desc }}</dd>
                                        </dl>
                                        <hr class="my-3" />
                                        <h6>
                                          Total
                                          <span class="font-weight-bold"
                                            >Rp.{{
                                              formatedCurrency(
                                                summaryDatas.total
                                              )
                                            }}</span
                                          >
                                        </h6>
                                      </div>
                                    </div>
                                  </div>

                                  <p>
                                    Pastikan nomer rekening dan nama rekening
                                    sudah benar seperti dibawah ini
                                  </p>
                                  <div
                                    class="card p-3 text-left mb-1"
                                    v-for="paymentData in paymentDatas"
                                    :key="paymentData.id"
                                  >
                                    <div class="row">
                                      <div class="col-md-6">
                                        <h4 class="font-weight-bold">
                                          {{ paymentData.bank }}
                                        </h4>
                                        <small>No Rekening.</small>
                                        <h5 class="font-weight-bold">
                                          {{ paymentData.bank_number }}
                                        </h5>
                                        <small>Atas Nama</small>
                                        <h4>{{ paymentData.bank_name }}</h4>
                                      </div>
                                      <div class="col-md-6">
                                        <small>Total Bayar.</small>
                                        <h5 class="font-weight-bold">
                                          Rp.{{ formatedCurrency(totalBayar) }}
                                        </h5>
                                        <small
                                          >Bayar sesuai nominal bayar hingga
                                          digit terakhir</small
                                        >
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </card>
                      </div>

                      <!-- Sidebar -->
                      <div class="col-md-4">
                        <base-button
                          type="button"
                          class="btn btn-sm btn-secondary btn-icon-only rounded-circle"
                          style="
                            position: absolute;
                            top: 10px;
                            right: 20px;
                            z-index: 999;
                          "
                          @click="modals.modal1 = false"
                        >
                          <span class="btn-inner--icon"
                            ><i class="fa fa-close"></i
                          ></span>
                        </base-button>
                        <img
                          :src="eventData.image_url"
                          class="img-center img-fluid"
                          style=""
                        />

                        <div v-if="totalBayar == 0">
                          <div class="container">
                            <div class="d-flex flex-row my-3">
                              <h3 class="heading mb-0">RINGKASAN PESANAN</h3>
                            </div>
                            <div v-if="summaryDatas.data">
                              <div
                                class="row"
                                v-for="itemSummary in summaryDatas.data"
                                :key="itemSummary.id"
                              >
                                <div class="col-md-8 col-sm-12 text-left">
                                  <p class="mb-0">
                                    {{ itemSummary.qty }} x
                                    {{ itemSummary.name }}
                                  </p>
                                </div>
                                <div class="col-md-4 col-sm-12">
                                  <p>
                                    Rp.{{ formatedCurrency(itemSummary.price) }}
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div v-if="summaryDatas.data.length == 0">
                              <p class="w-100 text-left">Tiket Kosong</p>
                            </div>

                            <div class="row">
                              <div class="col-md-12 p-3">
                                <div class="w-100 border-bottom"></div>
                              </div>

                              <div class="col-md-4 col-sm-12 text-left">
                                <p class="mb-0 font-weight-bold">Total</p>
                              </div>
                              <div class="col-md-8 col-sm-12">
                                <p class="font-weight-bold">
                                  Rp.{{ formatedCurrency(summaryDatas.total) }}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div v-if="totalBayar > 0">
                          <div class="container text-left mb-5">
                            <h3 class="heading my-3">
                              Pemesanan tiket berhasil!
                            </h3>
                            <p>
                              Tiket anda sudah masuk di dashboard silahkan cek
                              di dashboard anda lewat tombol dibawah ini.
                            </p>
                            <router-link :to="{ name: 'myTicket', params: {} }">
                              <base-button class="btn-1 w-100" type="success"
                                >Buka tiket saya</base-button
                              >
                            </router-link>
                            <div class="row">
                              <div class="col-md-4">
                                <hr />
                              </div>
                              <div class="col-md-4 py-3 text-center">
                                <p>Atau</p>
                              </div>
                              <div class="col-md-4">
                                <hr />
                              </div>
                            </div>
                            <router-link :to="{ name: 'landing', params: {} }">
                              <base-button class="btn-1 w-100" type="default"
                                >Telusuri acara lain.</base-button
                              >
                            </router-link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </modal>
                </div>
              </div>
              <div class="col-lg-4 order-lg-1">
                <!-- <div class="card-profile-stats d-flex">
                  <div>
                    <span class="heading">22</span>
                    <span class="description">Friends</span>
                  </div>
                  <div>
                    <span class="heading">10</span>
                    <span class="description">Photos</span>
                  </div>
                  <div>
                    <span class="heading">89</span>
                    <span class="description">Comments</span>
                  </div>
                </div> -->
              </div>
            </div>
            <div class="row justify-content-center mt-1">
              <div class="col-lg-8 order-lg-8">
                <div class="">
                  <img
                    :src="eventData.image_url"
                    class="img-center img-fluid"
                    style="width: 100%; height: 360px"
                  />
                </div>
              </div>
            </div>
            <div class="text-center mt-5">
              <h3>{{ eventData.title }}</h3>
              <div class="h6 font-weight-300">
                Dari <a href="#">{{ organizerData.name }}</a>
              </div>
              <div class="h6 mt-4">
                <i class="ni business_briefcase-24 mr-2"></i>
                {{ eventData.formated_from_date }}
              </div>
              <div>
                <i class="ni education_hat mr-2"></i>{{ eventData.location }}
              </div>
              <!-- <div class="mt-3">
                <a href="#section-map">View Map</a>
              </div> -->
            </div>
            <div class="mt-5 py-5 border-top">
              <div class="row justify-content-center">
                <div class="col-lg-9">
                  <p class="text-uppercase text-muted font-weight-bold">
                    {{ eventData.summary }}
                  </p>
                  <p class="heading-title text-warning">Tentang Acara Ini.</p>
                  <span v-html="eventData.desc"></span>
                </div>
              </div>
              <div class="row justify-content-center" v-if="eventData.tags">
                <div class="col-lg-9">
                  <div class="mt-5">
                    <p class="heading mb-0">Tag</p>

                    <div class="row col-lg-4 mt-3 float-left">
                      <div :key="tag.id" v-for="tag in eventData.tags">
                        <router-link
                          class="btn btn-small btn-outline-primary mb-2 mr-2"
                          :to="{
                            name: 'searchPage',
                            query: { query: 'tag-' + tag.name },
                          }"
                        >
                          {{ tag.name }}
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="section-map" class="mt-5 py-5 border-top">
              <div class="row justify-content-center">
                <div class="col-md-12 text-center">
                  <div class="h6">
                    <h3 class="heading text-warning mb-0">
                      {{ organizerData.name }}
                    </h3>
                    <p class="mt-2">
                      <span v-html="organizerData.desc"></span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </section>
  </div>
</template>
<script>
import Modal from "@/components/Modal.vue";
import axios from "axios";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import debounce from "lodash.debounce";
import sweatalert from "@/reactivities/sweatalert.js";

import { VueEditor } from "vue2-editor";

export default {
  components: {
    "v-select": vSelect,
    Modal,
    VueEditor,
  },
  mounted() {
    this.getData();
  },
  data() {
    return {
      eventUrl: this.$route.params.event,
      modals: {
        modal1: false,
        layers: {
          ticket: true,
          checkout: false,
          payment: false,
        },
      },
      valueSelect: "",
      eventData: "",
      organizerData: "",
      ticketDatas: [],
      customFormDatas: "",
      paymentDatas: "",
      totalBayar: 0,
      summaryDatas: {
        data: [],
        total: 0,
      },
      validationStatus: false,
      radio: {
        radio1: "radio1",
      },
      primaryForm: {
        firstName: null,
        lastName: null,
        email: null,
      },
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
      ],
      urlGetEvent: `${process.env.VUE_APP_API}/api/v1/event/`,
      urlOrder: `${process.env.VUE_APP_API}/api/v1/order`,
      purscQty: [],
      isLogin: JSON.parse(localStorage.getItem("token")) ? true : false,
      authToken: "Bearer " + JSON.parse(localStorage.getItem("token")),
      authUser: JSON.parse(localStorage.getItem("user")),
      isDisabled: true,
    };
  },
  methods: {
    getData: function () {
      axios.get(this.urlGetEvent + this.eventUrl).then(
        (res) => {
          this.eventData = res.data.data.event;
          this.organizerData = res.data.data.organizer;
          this.ticketDatas = res.data.data.tickets;
          this.customFormDatas = res.data.data.customForms;
          this.paymentDatas = res.data.data.payments;
        },
        (err) => {
          // if (err.response.data.message) {
          //   sweatalert.showAlert("error", err.response.data.message);
          // } else {
          //   sweatalert.showAlert("error", "Maaf telah terjadi kesalahan");
          // }
        }
      );
    },
    updateSummary: function (ticketId) {
      const selectedTicket = this.ticketDatas.find((x) => x.id == ticketId);

      if (selectedTicket) {
        if (selectedTicket.purchase_qty > 0) {
          const selectedSummary = this.summaryDatas.data.find(
            (x) => x.id == selectedTicket.id
          );

          if (selectedSummary) {
            selectedSummary.qty = selectedTicket.purchase_qty;
          } else {
            this.summaryDatas.data.push({
              id: selectedTicket.id,
              name: selectedTicket.name,
              qty: selectedTicket.purchase_qty,
              price: selectedTicket.price,
            });
          }
        }
        
        if (selectedTicket.purchase_qty == 0) {
          this.summaryDatas.data = this.summaryDatas.data.filter(
            (item) => item.id !== selectedTicket.id
          );
        }

        this.isDisabled = false;
      }

      const sumTotal = (arr) =>
        arr.reduce((sum, { price, qty }) => sum + price * qty, 0);

      this.summaryDatas.total = sumTotal(this.summaryDatas.data);

      if (this.summaryDatas.total == 0 && selectedTicket.purchase_qty == 0) {
        this.isDisabled = true;
      }
    },
    openModalTicket: function () {
      if (this.isLogin) {
        this.primaryForm.firstName = this.authUser.first_name;
        this.primaryForm.lastName = this.authUser.last_name;
        this.primaryForm.email = this.authUser.email;
      }

      this.modals.modal1 = true;
    },
    checkForm: function (e) {
      let text = "Apakah data ini sudah benar?";

      if (confirm(text) == true) {
        const formData = {
          cart: this.summaryDatas,
          primary_data: this.primaryForm,
          secondary_data: this.customFormDatas,
        };

        axios
          .post(`${this.urlOrder}`, formData, {
            headers: { Authorization: this.authToken },
          })
          .then(
            (res) => {
              if (res.data.success) {
                this.totalBayar = res.data.data.total_pay;

                this.changeLayer("payment");
              } else {
                alert(res.data.message);

                this.reloadPage();
              }
            },
            (err) => {
              // if (err.response.data.message) {
              //   sweatalert.showAlert("error", err.response.data.message);
              // } else {
              //   sweatalert.showAlert("error", "Maaf telah terjadi kesalahan");
              // }
            }
          );
      }
    },
    validateSecondaryData: function () {
      let statusValidate = false;

      Object.keys(this.customFormDatas).forEach((index) => {
        if (
          this.customFormDatas[index].required &&
          this.customFormDatas[index].value == null
        ) {
        }
      });

      return false;
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      // Push the name to submitted names
      this.submittedNames.push(this.name);
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("modal-prevent-closing");
      });
    },
    changeLayer: function (toLayer) {
      Object.keys(this.modals.layers).forEach((index) => {
        this.modals.layers[index] = false;
      });

      this.modals.layers[toLayer] = true;
    },
    formatedCurrency: function (number) {
      var reverse = number.toString().split("").reverse().join(""),
        value = reverse.match(/\d{1,3}/g);
      value = value.join(".").split("").reverse().join("");

      return value;
    },
    submitTicket: function () {
      if (this.summaryDatas.data.length) {
        this.changeLayer("checkout");
      } else {
        alert("Pilih Ticket terlebih dahulu!");
      }
    },
    reloadPage: debounce((e) => {
      location.reload();
    }, 1000),
  },
};
</script>
<style>
@media screen and (min-width: 900px) {
  .card-body.px-lg-5.py-lg-div5.bg-white {
    min-height: 320px;
  }
}
</style>
