<template>
  <div>
    <section class="section section-shaped section-lg my-0">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <router-link
              class="text-light"
              :to="{ name: 'myTicket', params: {} }"
            >
              <p class="text-warning">
                <i class="fa fa-chevron-left mr-1"></i> Kembali
              </p>
            </router-link>
            <div class="mb-4">
              <h3>
                Tiket Acara <span class="text-primary">{{ event.title }}</span>
              </h3>
              <p v-if="order">
                Order #{{ order.id }} total Rp.{{
                  formatedCurrency(order.total_pay)
                }}
                tanggal beli {{ order.created_at }} Infomasi Acara:
                {{ event.formated_from_date }}.
              </p>
              <i>
                {{ event.location }}
              </i>
            </div>
          </div>
          <div class="col-md-12">
            <hr class="my-2" />
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-9">
            <div v-if="editInformationContact == false">
              <h3>Nama Tiket</h3>
              <hr />
              <div class="row">
                <div class="col-md-10">
                  <b class="">Informasi Kontak</b>
                </div>
                <div class="col-md-2 text-right">
                  <p
                    class="cursor-pointer"
                    @click="editInformationContact = true"
                  >
                    <i class="fa fa-pencil"></i> Edit
                  </p>
                </div>
              </div>
              <div class="mt-2">
                <label for="">Nama Depan</label>
                <p class="m-0">{{ participant.first_name }}</p>
              </div>
              <div class="mt-2">
                <label for="">Nama Belakang</label>
                <p class="m-0">{{ participant.last_name }}</p>
              </div>
              <div class="mt-2">
                <label for="">Email</label>
                <p class="m-0">{{ participant.email }}</p>
              </div>
            </div>
            <div class="row" v-if="editInformationContact == true">
              <div class="col-md-12">
                <h5 class="font-weight-bold mb-3">Kontak Informasi</h5>
              </div>
              <div class="col-md-9 mb-3">
                <label for="" class="mb-0">Nama Depan</label>
                <base-input
                  alternative
                  class="border-white mb-0"
                  inputClasses="p-0"
                  type="text"
                  v-model="participant.first_name"
                  style="box-shadow: none !important"
                  placeholder="Nama Acara"
                  :required="true"
                >
                </base-input>
                <hr class="m-0 mt-3" />
              </div>
              <div class="col-md-9 mb-3">
                <label for="" class="mb-0">Nama Belakang</label>
                <base-input
                  alternative
                  class="border-white mb-0"
                  inputClasses="p-0"
                  type="text"
                  v-model="participant.last_name"
                  style="box-shadow: none !important"
                  placeholder="Nama Acara"
                  :required="true"
                >
                </base-input>
                <hr class="m-0 mt-3" />
              </div>
              <div class="col-md-9 mb-3">
                <label for="" class="mb-0">Email</label>
                <base-input
                  alternative
                  class="border-white mb-0"
                  inputClasses="p-0"
                  type="text"
                  v-model="participant.email"
                  style="box-shadow: none !important"
                  placeholder="Nama Acara"
                  :required="true"
                >
                </base-input>
                <hr class="m-0 mt-3" />
              </div>
              <div class="col-md-12">
                <h5 class="font-weight-bold mb-3">Informasi Lainnya</h5>
                <p>
                  Catatan: Informasi di bawah ini hanya sesuai dengan pembeli
                  tiket dan jika diperbarui hanya akan tercermin dalam catatan
                  pembeli tiket, bukan catatan setiap peserta.
                </p>
              </div>
            </div>
            <div
              class="row text-left my-3"
              v-if="editInformationContact == true"
            >
              <!-- <div class="col-md-12 mb-2" v-if="custom_form.length">
                <h4 class="font-weight-bold">Registration 1</h4>
              </div> -->
              <div
                v-for="data in custom_form"
                :key="data.id"
                :class="[data.type == 'short' ? 'col-md-6' : 'col-md-12']"
              >
                <div v-if="data.type == 'short'">
                  <label for="" class="mb-0"> {{ data.name }}</label>
                  <base-input
                    alternative
                    class="mb-3"
                    :placeholder="data.label"
                    v-model="data.value"
                    :required="data.required"
                  >
                  </base-input>
                </div>

                <div v-if="data.type == 'paragraph'">
                  <h5>{{ data.name }}</h5>
                  <vue-editor
                    v-model="data.value"
                    :editor-toolbar="customToolbar"
                    :required="data.required"
                    class="my-3"
                  />
                </div>

                <div v-if="data.type == 'single-choice'">
                  <h5>{{ data.name }}</h5>
                  <base-radio
                    v-for="option in data.options"
                    :key="option"
                    :name="option.label"
                    :value="option.label"
                    class="mb-3"
                    v-model="data.value"
                    :required="false"
                    :checked="data.value == option.label"
                  >
                    {{ option.label }}
                  </base-radio>
                </div>

                <div class="w-100 mt-2" v-if="data.type == 'multiple-choice'">
                  <h5 for="" class="mb-0 w-100">
                    {{ data.name }}
                  </h5>
                  <v-select
                    class="mt-2 mb-3 w-100 float-left"
                    :filterable="false"
                    :options="data.options"
                    v-model="data.value"
                    :clearable="false"
                    :reduce="data.options.label"
                    :getOptionLabel="data.options.label"
                    :required="!data.value"
                    :multiple="true"
                  >
                  </v-select>
                </div>
              </div>
              <div class="col-md-6 mt-4">
                <base-button
                  class="btn-1 float-right w-100"
                  type="grey"
                  @click="editInformationContact = false"
                  >Batal</base-button
                >
              </div>
              <div class="col-md-6 mt-4">
                <base-button
                  class="btn-1 float-right w-100"
                  type="primary"
                  @click="saveUpdate"
                  >Simpan Perubahan</base-button
                >
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <base-button
              class="btn-1 float-right w-100"
              type="primary"
              v-if="order.status == 'completed'"
              @click="printTicket"
              >Cetak Tiket</base-button
            >
            <i v-if="order.status == 'paid'">Pembayaran sedang diproses...</i>
            <base-button
              class="btn-1 float-right w-50"
              type="success"
              v-if="order.status == 'pending'"
              @click="modalUploadTransfer = true"
              >Bayar Tiket</base-button
            >
          </div>
        </div>
      </div>
    </section>
    <modal
      :show="modalUploadTransfer"
      body-classes="p-0"
      modal-classes="modal-dialog-centered"
    >
      <div class="row">
        <!-- Ticket Section -->
        <div class="col-md-12" style="padding-right: 0px !important">
          <card
            type="secondary"
            shadow
            header-classes="bg-white"
            body-classes="px-lg-1 py-lg-3 bg-white"
            footer-classes="bg-white"
            class="border-0"
          >
            <div>
              <div class="container">
                <form role="form" @submit.prevent="uploadTransfer">
                  <div class="row text-left border-bottom mb-3">
                    <div class="col-md-12">
                      <h5 class="mb-3">Upload Bukti Transfer</h5>
                      <hr class="mb-3 mt-3" />

                      <label for="" class="mb-0">Bukti Transfer</label>
                      <base-input
                        id="input-image-cover"
                        type="file"
                        accept="image/png, image/jpeg, image/bmp"
                        ref="fileImageCover"
                        @change="onChangeImageCover"
                      >
                      </base-input>
                    </div>
                    <div class="col-md-6">
                      <base-button
                        type="secondary"
                        class="mr-3 w-100 my-4"
                        @click="modalUploadTransfer = false"
                        >Batal</base-button
                      >
                    </div>
                    <div class="col-md-6">
                      <base-button
                        type="primary"
                        class="mr-3 w-100 my-4"
                        nativeType="submit"
                        >Upload</base-button
                      >
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </card>
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
import axios from "axios";
import { VueEditor } from "vue2-editor";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import debounce from "lodash.debounce";
import { sweatalert } from "@/reactivities/sweatalert.js";
import Modal from "@/components/Modal.vue";

export default {
  components: {
    VueEditor,
    "v-select": vSelect,
    Modal,
  },
  created() {
    this.getData();
  },
  data() {
    return {
      listTickets: "",
      editInformationContact: false,
      modalUploadTransfer: false,
      urlGetMyTicket: `${process.env.VUE_APP_API}/api/v1/my/ticket/`,
      urlSave: `${process.env.VUE_APP_API}/api/v1/my/ticket/`,
      urlPrintTicket: `${process.env.VUE_APP_API}/api/v1/print/ticket/`,
      urlUploadTransfer: `${process.env.VUE_APP_API}/api/v1/my/ticket/`,
      urlupdateInfomationContact: `${process.env.VUE_APP_API}/api/v1/print/ticket/`,
      authToken: "Bearer " + JSON.parse(localStorage.getItem("token")),
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
      ],
      order: "",
      event: "",
      participant: "",
      custom_form: "",
      imageUploadTransferUrl: "",
      imageUploadTransfer: "",
    };
  },
  methods: {
    getData: function () {
      axios
        .get(`${this.urlGetMyTicket}${this.$route.params.orderId}`, {
          headers: {
            Authorization: this.authToken,
          },
        })
        .then(
          (res) => {
            if (res.data.success) {
              this.order = res.data.data.order;
              this.event = res.data.data.order_event;
              this.participant = res.data.data.participant;
              this.custom_form = res.data.data.custom_form;
            }
          },
          (err) => {
            sweatalert.showAlert("error", "Maaf telah terjadi kesalahan");
          }
        );
    },
    printTicket: function () {
      const participantName = `${this.participant.full_name.toLowerCase()}-` ?? '';
      const eventName = this.event.title.toLowerCase().replace(' ', '-');
      const filename = `tiket-${participantName}${eventName}-${Date.now()}.pdf`;
      
      axios
        .get(`${this.urlPrintTicket}${this.$route.params.orderId}`, {
          headers: {
            Authorization: this.authToken,
            Accept: "application/json",
          },
          responseType: "blob",
        })
        .then(
          (response) => {
            if (response.data) {
              // var blobObj = new Blob([atob(response.data)], { type: "application/pdf" });

              // create file link in browser's memory
              const href = window.URL.createObjectURL(
                new Blob([response.data], { type: "application/pdf" })
              );

              // create "a" HTML element with href to file & click
              const link = document.createElement("a");
              link.href = href;
              link.setAttribute("download", filename); //or any other extension
              document.body.appendChild(link);
              link.click();
              // clean up "a" element & remove ObjectURL
              document.body.removeChild(link);
              URL.revokeObjectURL(href);
            }
          },
          (err) => {
            // sweatalert.showAlert("error", "Maaf telah terjadi kesalahan");
          }
        );
    },
    formatedCurrency: function (number) {
      var reverse = number.toString().split("").reverse().join(""),
        value = reverse.match(/\d{1,3}/g);
      value = value.join(".").split("").reverse().join("");

      return value;
    },
    saveUpdate: function () {
      let data = {
        data_participant: this.participant,
        data_custom_data: this.custom_form,
      };
      axios
        .post(`${this.urlSave}${this.$route.params.orderId}/save`, data, {
          headers: { Authorization: this.authToken },
        })
        .then(
          (res) => {
            if (res.data.success) {
              this.participant = res.data.data.participant;
              this.custom_form = res.data.data.custom_datas;
              sweatalert.showAlert("success", res.data.message);

              editInformationContact = false;
            } else {
              sweatalert.showAlert("error", res.data.message);

              editInformationContact = false;
            }
          },
          (err) => {
            if (err.response.data.message) {
              sweatalert.showAlert("error", err.response.data.message);
            } else {
              sweatalert.showAlert("error", "Maaf telah terjadi kesalahan");
            }
          }
        );
    },
    openModalUploadTransfer: function () {
      this.editInformationContact = true;
    },
    onChangeImageCover: function (e) {
      const file = e.target.files[0];

      const image = URL.createObjectURL(file);
      this.imageUploadTransferUrl = image;
      this.imageUploadTransfer = file;
    },
    uploadTransfer: function () {
      const myFormData = new FormData();
      const orderId = this.$route.params.orderId;

      myFormData.append("order_id", orderId);
      myFormData.append("image_upload_transfer", this.imageUploadTransfer);

      axios
        .post(`${this.urlUploadTransfer}${orderId}/upload`, myFormData, {
          headers: {
            Authorization: this.authToken,
            "Content-Type": "multipart/form-data",
          },
        })
        .then(
          (res) => {
            if (res.data.success) {
              sweatalert.showAlert("success", res.data.message);
              this.order = res.data.data;

              this.modalUploadTransfer = false;
            }
          },
          (err) => {
            if (err.response.data.message) {
              sweatalert.showAlert("error", err.response.data.message);
            } else {
              sweatalert.showAlert("error", "Maaf telah terjadi kesalahan");
            }
          }
        );
    },
    reloadPage: debounce((e) => {
      // location.reload();
    }, 1000),
  },
};
</script>
<style>
</style>
