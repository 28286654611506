<template>
  <div>
    <section class="section section-shaped section-lg my-0">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="mb-4">
              <base-button
                class="btn-1 float-right"
                type="primary"
                @click="modals.modalCreate.status = true"
                >Buat Tiket Baru</base-button
              >

              <modal
                :show="modals.modalCreate.status"
                body-classes="p-0"
                modal-classes="modal-dialog-centered"
              >
                <div class="row">
                  <!-- Ticket Section -->
                  <div class="col-md-12" style="padding-right: 0px !important">
                    <card
                      type="secondary"
                      shadow
                      header-classes="bg-white"
                      body-classes="px-lg-1 py-lg-3 bg-white"
                      footer-classes="bg-white"
                      class="border-0"
                    >
                      <div>
                        <div class="container">
                          <form role="form" @submit.prevent="saveTicket">
                            <div class="row text-left border-bottom mb-3">
                              <div class="col-md-12">
                                <h5 class="mb-3">Tambah Tiket Baru</h5>
                                <hr class="mb-3 mt-3" />

                                <label for="" class="mb-0">Nama Tiket</label>
                                <base-input
                                  alternative
                                  class="mb-3"
                                  type="text"
                                  placeholder="Nama"
                                  :required="true"
                                  v-model="modals.modalCreate.data.ticketName"
                                >
                                </base-input>
                              </div>
                              <div class="col-md-12">
                                <label for="" class="mb-0">Total Tiket</label>
                                <base-input
                                  alternative
                                  class="mb-3"
                                  type="number"
                                  placeholder="Total tiket"
                                  :required="true"
                                  v-model="
                                    modals.modalCreate.data.ticketCapacity
                                  "
                                >
                                </base-input>
                              </div>
                              <div class="col-md-12">
                                <label for="" class="mb-0"
                                  >Harga Per-Tiket</label
                                >
                                <base-input
                                  alternative
                                  class="mb-3"
                                  type="number"
                                  placeholder="Harga Pertiket"
                                  :required="true"
                                  v-model="modals.modalCreate.data.ticketPrice"
                                >
                                </base-input>
                              </div>

                              <div class="col-md-6">
                                <label for="" class="mb-2"
                                  >Tanggal tersedianya tiket</label
                                >
                                <base-input
                                  addon-left-icon="ni ni-calendar-grid-58"
                                >
                                  <flat-pickr
                                    :v-slot="{ focus, blur }"
                                    @on-open="focus"
                                    @on-close="blur"
                                    :config="configDatePickerTicketStart"
                                    class="form-control datepicker"
                                    @on-change="onChangeCreateTicketSalesStart"
                                    v-model="
                                      modals.modalCreate.data.ticketSalesStart
                                    "
                                  >
                                  </flat-pickr>
                                </base-input>
                              </div>
                              <div class="col-md-6">
                                <label for="" class="mb-2"
                                  >Waktu berakhirnya tiket</label
                                >
                                <base-input
                                  addon-left-icon="ni ni-calendar-grid-58"
                                >
                                  <flat-pickr
                                    :v-slot="{ focus, blur }"
                                    @on-open="focus"
                                    @on-close="blur"
                                    :config="configDatePickerTicketEnd"
                                    class="form-control datetimepicker"
                                    v-model="
                                      modals.modalCreate.data.ticketSalesEnd
                                    "
                                    @on-change="onChangeCreateTicketSalesEnd"
                                  />
                                </base-input>
                              </div>

                              <div class="col-md-6">
                                <label for="" class="mb-0"
                                  >Minimum Order
                                </label>
                                <base-input
                                  alternative
                                  class="mb-3"
                                  type="number"
                                  placeholder="1"
                                  :required="true"
                                  v-model="
                                    modals.modalCreate.data.ticketMinOrder
                                  "
                                >
                                </base-input>
                              </div>

                              <div class="col-md-6">
                                <label for="" class="mb-0"
                                  >Maksimum Order</label
                                >
                                <base-input
                                  alternative
                                  class="mb-3"
                                  type="number"
                                  placeholder="10"
                                  :required="true"
                                  v-model="
                                    modals.modalCreate.data.ticketMaxOrder
                                  "
                                >
                                </base-input>
                              </div>

                              <div class="col-md-12">
                                <label for="" class="mb-0"
                                  >Deskripsi Tiket</label
                                >
                                <base-input
                                  alternative
                                  class="mb-3"
                                  type="text"
                                  placeholder="Deskripsi Tiket"
                                  v-model="modals.modalCreate.data.ticketDesc"
                                >
                                </base-input>
                              </div>

                              <div class="col-md-6">
                                <base-button
                                  type="secondary"
                                  class="mr-3 w-100 my-4"
                                  @click="modals.modalCreate.status = false"
                                  >Batal</base-button
                                >
                              </div>
                              <div class="col-md-6">
                                <base-button
                                  type="primary"
                                  class="mr-3 w-100 my-4"
                                  nativeType="submit"
                                  >Simpan Tiket</base-button
                                >
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </card>
                  </div>
                </div>
              </modal>

              <modal
                :show="modals.modalEdit.status"
                body-classes="p-0"
                modal-classes="modal-dialog-centered"
              >
                <div class="row">
                  <!-- Ticket Section -->
                  <div class="col-md-12" style="padding-right: 0px !important">
                    <card
                      type="secondary"
                      shadow
                      header-classes="bg-white"
                      body-classes="px-lg-1 py-lg-3 bg-white"
                      footer-classes="bg-white"
                      class="border-0"
                    >
                      <div class="container">
                        <form role="form" @submit.prevent="updateTicket">
                          <div class="row text-left border-bottom mb-3">
                            <div class="col-md-12">
                              <h5 class="mb-3">Edit Tiket</h5>
                              <hr class="mb-3 mt-3" />

                              <label for="" class="mb-0">Nama Tiket</label>
                              <base-input
                                alternative
                                class="mb-3"
                                type="text"
                                placeholder="Nama"
                                :required="true"
                                v-model="modals.modalEdit.data.ticketName"
                              >
                              </base-input>
                            </div>
                            <div class="col-md-12">
                              <label for="" class="mb-0">Total Tiket</label>
                              <base-input
                                alternative
                                class="mb-3"
                                type="number"
                                placeholder="Total tiket"
                                :required="true"
                                v-model="modals.modalEdit.data.ticketCapacity"
                              >
                              </base-input>
                            </div>
                            <div class="col-md-12">
                              <label for="" class="mb-0">Harga Per-Tiket</label>
                              <base-input
                                alternative
                                class="mb-3"
                                type="number"
                                placeholder="Harga Pertiket"
                                :required="true"
                                v-model="modals.modalEdit.data.ticketPrice"
                              >
                              </base-input>
                            </div>

                            <div class="col-md-6">
                              <label for="" class="mb-2"
                                >Tanggal tersedianya tiket</label
                              >
                              <base-input
                                addon-left-icon="ni ni-calendar-grid-58"
                              >
                                <flat-pickr
                                  :v-slot="{ focus, blur }"
                                  @on-open="focus"
                                  @on-close="blur"
                                  :config="configDatePickerTicketStart"
                                  class="form-control datepicker"
                                  v-model="
                                    modals.modalEdit.data.ticketSalesStart
                                  "
                                  @on-change="onChangeEditTicketSalesStart"
                                >
                                </flat-pickr>
                              </base-input>
                            </div>
                            <div class="col-md-6">
                              <label for="" class="mb-2"
                                >Tanggal berakhirnya tiket</label
                              >
                              <base-input
                                addon-left-icon="ni ni-calendar-grid-58"
                              >
                                <flat-pickr
                                  :v-slot="{ focus, blur }"
                                  @on-open="focus"
                                  @on-close="blur"
                                  :config="configDatePickerTicketEnd"
                                  class="form-control datepicker"
                                  v-model="modals.modalEdit.data.ticketSalesEnd"
                                  @on-change="onChangeEditTicketSalesEnd"
                                >
                                </flat-pickr>
                              </base-input>
                            </div>

                            <div class="col-md-6">
                              <label for="" class="mb-0">Minimum Order </label>
                              <base-input
                                alternative
                                class="mb-3"
                                type="number"
                                placeholder="1"
                                :required="true"
                                v-model="modals.modalEdit.data.ticketMinOrder"
                              >
                              </base-input>
                            </div>

                            <div class="col-md-6">
                              <label for="" class="mb-0">Maksimum Order</label>
                              <base-input
                                alternative
                                class="mb-3"
                                type="number"
                                placeholder="10"
                                :required="true"
                                v-model="modals.modalEdit.data.ticketMaxOrder"
                              >
                              </base-input>
                            </div>

                            <div class="col-md-12">
                              <label for="" class="mb-0">Deskripsi Tiket</label>
                              <base-input
                                alternative
                                class="mb-3"
                                type="text"
                                placeholder="Deskripsi Tiket"
                                v-model="modals.modalEdit.data.ticketDesc"
                              >
                              </base-input>
                            </div>
                            <div class="col-md-6">
                              <base-button
                                type="secondary"
                                class="mr-3 w-100 my-4"
                                @click="modals.modalEdit.status = false"
                                >Batal</base-button
                              >
                            </div>
                            <div class="col-md-6">
                              <base-button
                                type="primary"
                                class="mr-3 w-100 my-4"
                                nativeType="submit"
                                >Simpan Tiket</base-button
                              >
                            </div>
                          </div>
                        </form>
                      </div>
                    </card>
                  </div>
                </div>
              </modal>

              <h3>Daftar Tiket</h3>
            </div>
            <div class="mt-3">
              <table class="table table-ticket">
                <thead></thead>
                <tbody>
                  <div v-if="listTickets.length > 0">
                    <tr v-for="list in listTickets" :key="list.id">
                      <td class="col-md-8 col-12">
                        <div class="row">
                          <div class="col-md-12">
                            {{ list.name }}
                            <div class="description">
                              <p>
                                <i class="fa fa-circle text-success"></i>
                                {{ list.status_message }}
                                <small><i class="fa fa-circle"></i></small>
                                Berakhir pada, {{ list.formatted_sales_end }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td class="col-md-4 col-12">
                        <div class="row">
                          <div class="col-md-7">
                            <dd class="font-weight-bold">
                              {{ list.formatted_price }}
                            </dd>
                          </div>
                          <div class="col-md-5 text-right">
                            <i
                              class="fa fa-eye cursor-pointer mr-4"
                              @click="editTicket(list.id)"
                            ></i>
                            <i
                              class="fa fa-trash text-danger cursor-pointer"
                              @click="confirmationDeleteTicket(list.id)"
                            ></i>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </div>
                  <tr v-if="listTickets.length == 0">
                    <p>Ticket anda kosong</p>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="row">
              <div class="col-md-12">
                <router-link
                  class="text-light"
                  :to="{ name: 'createPublish', params: {} }"
                >
                  <base-button type="primary float-right" class="mr-3 my-4"
                    >Selanjutnya</base-button
                  >
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import Modal from "@/components/Modal.vue";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import "flatpickr/dist/flatpickr.css";
import axios from "axios";
import { sweatalert } from "@/reactivities/sweatalert.js";
import debounce from "lodash.debounce";

flatpickr(".datetimepicker", {
  enableTime: true,
  dateFormat: "Y-m-d H:i",
});

export default {
  components: {
    Modal,
    flatPickr
  },
  created() {
    this.getData();
  },
  data() {
    return {
      eventId: this.$route.params.e,
      modals: {
        modalCreate: {
          status: false,
          data: {
            ticketName: "",
            ticketCapacity: "",
            ticketPrice: "",
            ticketMinOrder: "",
            ticketMaxOrder: "",
            ticketSalesStart: "",
            ticketSalesEnd: "",
            ticketDesc: "",
          },
        },
        modalEdit: {
          status: false,
          data: {
            ticketId: "",
            ticketName: "",
            ticketCapacity: "",
            ticketPrice: "",
            ticketMinOrder: "",
            ticketMaxOrder: "",
            ticketSalesStart: "",
            ticketSalesEnd: "",
            ticketDesc: "",
          },
        },
      },
      ticketSalesEnd: null,
      listTickets: [],
      dates: {
        simple: "2018-07-17",
        range: "2018-07-17 to 2018-07-19",
      },
      configDatePickerTicketStart: {
        enableTime: true,
        dateFormat: "Y-m-d H:i",
        allowInput: true,
        minDate: "today",
      },
      configDatePickerTicketEnd: {
        enableTime: true,
        dateFormat: "Y-m-d H:i",
        allowInput: true,
        minDate: "today",
      },
      url: `${process.env.VUE_APP_API}/api/v1/dashboard/e/ticket`,
      authToken: "Bearer " + JSON.parse(localStorage.getItem("token")),
    };
  },
  methods: {
    getData: function () {
      if (JSON.parse(localStorage.getItem("token"))) {
        axios
          .get(`${this.url}/${this.eventId}`, {
            headers: { Authorization: this.authToken },
          })
          .then(
            (res) => {
              if (res.data) {
                this.listTickets = res.data.tickets;
                this.dataEvent = res.data.event;
              }
            },
            (err) => {
              if (err.response.data.message) {
                sweatalert.showAlert("error", err.response.data.message);
              } else {
                sweatalert.showAlert("error", "Maaf telah terjadi kesalahan");
              }
            }
          );
      }
    },
    saveTicket: function () {
      const data = {
        event_id: this.eventId,
        name: this.modals.modalCreate.data.ticketName,
        price: this.modals.modalCreate.data.ticketPrice,
        capacity: this.modals.modalCreate.data.ticketCapacity,
        min_order: this.modals.modalCreate.data.ticketMinOrder,
        max_order: this.modals.modalCreate.data.ticketMaxOrder,
        sales_start: this.modals.modalCreate.data.ticketSalesStart,
        sales_end: this.modals.modalCreate.data.ticketSalesEnd,
        desc: this.modals.modalCreate.data.ticketDesc,
      };

      axios
        .post(this.url, data, {
          headers: { Authorization: this.authToken },
        })
        .then(
          (res) => {
            if (res.data.success) {
              this.modals.modalCreate.status = false;
              sweatalert.showAlert("success", res.data.message);
              this.listTickets = res.data.data;

              this.$router.push({
                name: "createPublish",
                params: { e: this.eventId },
              });
            }
          },
          (err) => {
            if (err.response.data.message) {
              sweatalert.showAlert("error", err.response.data.message);
            } else {
              sweatalert.showAlert("error", "Maaf telah terjadi kesalahan");
            }
          }
        );
    },
    editTicket: function (ticketId) {
      const selectedTicket = this.listTickets.find((x) => x.id == ticketId);

      this.modals.modalEdit.data.ticketId = ticketId;
      this.modals.modalEdit.data.ticketName = selectedTicket.name;
      this.modals.modalEdit.data.ticketPrice = selectedTicket.price;
      this.modals.modalEdit.data.ticketCapacity = selectedTicket.capacity;
      this.modals.modalEdit.data.ticketMinOrder = selectedTicket.min_order;
      this.modals.modalEdit.data.ticketMaxOrder = selectedTicket.max_order;
      this.modals.modalEdit.data.ticketSalesStart = selectedTicket.sales_start;
      this.modals.modalEdit.data.ticketSalesEnd = selectedTicket.sales_end;
      this.modals.modalEdit.data.ticketDesc = selectedTicket.desc;

      this.modals.modalEdit.status = true;
    },
    updateTicket: function () {
      const data = {
        name: this.modals.modalEdit.data.ticketName,
        price: this.modals.modalEdit.data.ticketPrice,
        capacity: this.modals.modalEdit.data.ticketCapacity,
        min_order: this.modals.modalEdit.data.ticketMinOrder,
        max_order: this.modals.modalEdit.data.ticketMaxOrder,
        sales_start: this.modals.modalEdit.data.ticketSalesStart,
        sales_end: this.modals.modalEdit.data.ticketSalesEnd,
        desc: this.modals.modalEdit.data.ticketDesc,
      };

      axios
        .post(`${this.url}/${this.modals.modalEdit.data.ticketId}`, data, {
          headers: { Authorization: this.authToken },
        })
        .then(
          (res) => {
            if (res.data.success) {
              sweatalert.showAlert("success", res.data.message);
              this.listTickets = res.data.data;
            } else {
              sweatalert.showAlert("error", res.data.message);
            }
            this.modals.modalEdit.status = false;

            this.reloadPage();
          },
          (err) => {
            if (err.response.data.message) {
              sweatalert.showAlert("error", err.response.data.message);
            } else {
              sweatalert.showAlert("error", "Maaf telah terjadi kesalahan");
            }
          }
        );
    },
    confirmationDeleteTicket: function (ticketId) {
      let text =
        "Anda hanya punya satu tiket. Jika menghapus acara anda akan kami unpublish. Apakah anda yakin?";

      if (this.listTickets.length < 2) {
        if (confirm(text) == true) {
          this.deleteTicket(ticketId);
        } else {
          return false;
        }
      } else {
        this.deleteTicket(ticketId);
      }
    },
    deleteTicket: function (ticketId) {
      let text = "Apakah anda ingin menghapus tiket ini?";

      if (confirm(text) == true) {
        axios
          .get(`${this.url}/delete/${ticketId}`, {
            headers: { Authorization: this.authToken },
          })
          .then(
            (res) => {
              if (res.data.success) {
                sweatalert.showAlert("success", res.data.message);
                this.listTickets = res.data.data;

                this.$router.push({
                  name: "createTicket",
                  params: { e: this.eventId },
                });
              }
            },
            (err) => {
              if (err.response.data.message) {
                sweatalert.showAlert("error", err.response.data.message);
              } else {
                sweatalert.showAlert("error", "Maaf telah terjadi kesalahan");
              }
            }
          );
      }
    },
    onChangeCreateTicketSalesStart: function (
      selectedDates,
      dateStr,
      instance
    ) {
      this.modals.modalCreate.data.ticketSalesStart = dateStr;
    },
    onChangeCreateTicketSalesEnd: function (selectedDates, dateStr, instance) {
      this.modals.modalCreate.data.ticketSalesEnd = dateStr;
    },
    onChangeEditTicketSalesStart: function (selectedDates, dateStr, instance) {
      this.modals.modalEdit.data.ticketSalesStart = dateStr;
    },
    onChangeEditTicketSalesEnd: function (selectedDates, dateStr, instance) {
      this.modals.modalEdit.data.ticketSalesEnd = dateStr;
    },
    reloadPage: debounce((e) => {}, 3000),
  },
};
</script>
<style>
.table-ticket td {
  border-top: none !important;
  border-bottom: 1px solid #dbdae3;
  margin-bottom: 20px;
}
</style>
